
import { v4 } from "uuid";
import * as AccionesFact from "./acciones/AccionesTypeFact";
import ActionTypeFact from './acciones/ActionTypeFact'
// import { Error } from "../../comun/Eventos";

/** 
 * @param {object} state Estado
 * @param {object} action Accion
 * @returns {void} Retorna un nuevo estado 
 */
export default function QueryFactReducer(state, action) {

    // const estadoTemp = {...state,domain:{...state.domain},};
    // estadoTemp.domain.cargado={};
    const nuevoEstado = JSON.parse(JSON.stringify(state));
    // nuevoEstado.domain.cargado = state.domain.cargado;  

    return action.ejecutar(state, nuevoEstado);
    
}
