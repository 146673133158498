

export default function MenuItemsRRHH(dispatch) {
    return [
        {
            label: "Nuevo Asociado",
            icon: "pi pi-fw pi-plus",
            // command: abrirVentanaCrearFia,
        },
        {
            label: "Listar Asociados",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
    ];
}

