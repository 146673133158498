import React, { useReducer, useContext, useRef } from "react";
import PrimeReact from "primereact/api";

import GlobalContext from "../../../_comun/contexto/GlobalContext";
import PrincipalReducer from "../../reductores/PrincipalReducer";
import PantallaPrincipal from "../pantallas/PantallaPrincipal";

import PresentadorClienteAFacturar from "../../../facturacion/componentes/presentadores/PresentadorClienteAFacturar";
import PresentadorQueryClientesAFacturar from "../../../facturacion/componentes/presentadores/PresentadorQueryClientesAFacturar";
import VentanaClienteAFacturar from "../../../facturacion/reductores/ventanas/VentanaClienteAFacturar";
import VentanaQueryClientesAFacturar from "../../../facturacion/reductores/ventanas/VentanaQueryClientesAFacturar";

// import { v4 } from "uuid";

// import ClienteAFacturar from "../../entidades/ClienteAFacturar";
// import DataDeRespuestas_Facturacion from "../../servicios/DataEstaticaDeRespuestas.json";

const PresentadorPrincipal = () => {
    const initialState = {
        ventanas: [],
        // banderaDeNotificacionEventos:true
        banderaCerrandoSesion: false,
    };

    const [state, dispatchPrincipal] = useReducer(PrincipalReducer, initialState);

    // PrimeReact.autoZIndex = false;
    PrimeReact.autoZIndex = true;

    PrimeReact.zIndex = {
        modal: 900, // dialog, sidebar
        overlay: 1000, // dropdown, overlaypanel
        menu: 1000, // overlay menus
        tooltip: 1100, // tooltip
        toast: 1200, // toast
    };

    const lista_ventanas = state.ventanas.map((ventana) => {
        // if (ventana.tipo === DialogType.CLIENTE_A_FACTURAR) {
        if (ventana instanceof VentanaClienteAFacturar) {
            return <PresentadorClienteAFacturar key={ventana.id} ventana={ventana} />;
        }

        // if (ventana.tipo === DialogType.QUERY_CLIENTES_A_FACTURAR) {
        if (ventana instanceof VentanaQueryClientesAFacturar) {
            return <PresentadorQueryClientesAFacturar key={ventana.id} ventana={ventana} />;
        }

        return null;
    });

    const refPantallaPrincipal = useRef(null);

    const notificar = function (notificacion) {
        refPantallaPrincipal.current.notificar(notificacion);
    };

    /* const getVentanaCargadaEnLaLista = function (ventanaAAbrir) {
        const ventanaReemplazante = state.ventanas.find((ventana) => {
            if (ventana.puedeHacersePasarPor(ventanaAAbrir)){
                return true;
            } else {
                return false;
            }
        });
        if (ventanaReemplazante) {
            return ventanaReemplazante;
        } else {
            ventanaAAbrir.posicionZ = state.ventanas.length + 1;
            state.ventanas.push(ventanaAAbrir);
            return ventanaAAbrir;
        }
    }; */

    const values = useContext(GlobalContext);
     
    
    
    /* return (
        <GlobalContext.Provider
            value={{
                ...values,
                lista_ventanas,
                // evento: state.evento,
                //   abrirVentanaClienteAFacturar,
                //   abrirVentanaCrearFia,
                // abrirVentanaQueryFias,
                //   cerrarVentana,
                //   posicionarPrimero,
                //   notificarRegistroDeFia,
                dispatchPrincipal,
                notificar,
                // cerrarSesion
            }}
        >
            <PantallaPrincipal ref={refPantallaPrincipal} />
        </GlobalContext.Provider>
    );  */
    

    values.lista_ventanas = lista_ventanas;
    values.dispatchPrincipal = dispatchPrincipal;
    values.notificar = notificar;
    values.estadoPrin = state;
    return <PantallaPrincipal ref={refPantallaPrincipal} />

};

export default PresentadorPrincipal;
