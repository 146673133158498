
import _ from "lodash";
import ActionTypeFact from './ActionTypeFact'
import { ModosOp } from "../../../_comun/Constantes";

export default class ActionClienteSet extends ActionTypeFact {

    constructor(pathAtributo, valorASetear) {
        super(valorASetear);
        this.pathAtributo = pathAtributo;
    }
    
    ejecutar(estado, nuevoEstado) {
        const estado_Cargado_EstaVacio =
            Object.keys(estado.domain.cargado).length < 1 ? true : false;
        if (estado_Cargado_EstaVacio) {
            this.darleUnNuevoValorALaPropiedadDelaNuevaEntidad(estado, nuevoEstado);
            return nuevoEstado;
        }

        // let valorPorSetear=_.get(nuevoEstado.domain, pathAtributo);
        let valorDePropPorSetear = this.payload;
        let valorDePropCargada = _.get(estado.domain.cargado, this.pathAtributo, null);

        const sonValoresIguales = valorDePropPorSetear === valorDePropCargada;
        // const existeAtributoEnEstadoParaUpdate = _.has(nuevoEstado.paraUpdate, pathAtributo);

        if (sonValoresIguales) {
            if (_.has(estado.domain.update, this.pathAtributo)) {
                nuevoEstado.domain.nroUpdates--;
                this.hacerALaPropiedadIgualASuVersionCargadaCuandoNoloEraAntes(estado, nuevoEstado);
                if (nuevoEstado.domain.nroUpdates === 0) {
                    nuevoEstado.flag.modoOperacion = ModosOp.OK;
                }
                return nuevoEstado;
            }
            return nuevoEstado;
        }

        //sonValoresDiferentes y la prop existe en el estado Update
        if (_.has(estado.domain.update, this.pathAtributo)) {
            if (valorDePropPorSetear === _.get(estado.domain.update, this.pathAtributo)) {
                return nuevoEstado;
            }
            this.hacerALaPropiedadDiferenteDeSuVersionCargadaPorEnesimaVez(estado, nuevoEstado);
            return nuevoEstado;
        }

        //sonValoresDiferentes y la prop NO existe en el estado Update
        nuevoEstado.domain.nroUpdates++;
        this.hacerALaPropiedadDiferenteDeSuVersionCargadaPorPrimeraVez(estado, nuevoEstado);
        if (nuevoEstado.domain.nroUpdates === 1) {
            nuevoEstado.flag.modoOperacion = ModosOp.MODIFICAR;
        }
        return nuevoEstado;
    }

    /**@abstract */
    darleUnNuevoValorALaPropiedadDelaNuevaEntidad(estado, nuevoEstado) {
        _.set(nuevoEstado.domain.screen, this.pathAtributo, this.payload);
    }

    /**@abstract */
    hacerALaPropiedadIgualASuVersionCargadaCuandoNoloEraAntes(estado, nuevoEstado) {
        const valorPropCargada = _.get(estado.domain.cargado, this.pathAtributo)
        _.set(nuevoEstado.domain.screen, this.pathAtributo, valorPropCargada);
        _.unset(nuevoEstado.domain.update, this.pathAtributo);
    }

    /**@abstract */
    hacerALaPropiedadDiferenteDeSuVersionCargadaPorPrimeraVez(estado, nuevoEstado) {
        // nuevoEstado.domain.screen.tipoDoc = estado.domain.cargado.tipoDoc;  
        _.set(nuevoEstado.domain.screen, this.pathAtributo, this.payload);
        _.set(nuevoEstado.domain.update, this.pathAtributo, this.payload);
    }

    /**@abstract */
    hacerALaPropiedadDiferenteDeSuVersionCargadaPorEnesimaVez(estado, nuevoEstado) {
        _.set(nuevoEstado.domain.screen, this.pathAtributo, this.payload);
        _.set(nuevoEstado.domain.update, this.pathAtributo, this.payload);
    }

    
/* 

    continuarCambiosEnEstado(estado, nuevoEstado, pathAtributo) {
        let estadoRegistroEstaVacio =
            Object.keys(nuevoEstado.domain.cargado).length < 1 ? true : false;
        if (estadoRegistroEstaVacio) {
            return nuevoEstado;
        }

        let valorPorSetear = _.get(nuevoEstado.domain.screen, pathAtributo);
        let valorDelEstadoRegistro = _.get(nuevoEstado.domain.cargado, pathAtributo);

        const sonValoresDiferentes = valorPorSetear !== valorDelEstadoRegistro;
        const existeAtributoEnEstadoParaUpdate = _.has(nuevoEstado.domain.update, pathAtributo);

        if (sonValoresDiferentes && existeAtributoEnEstadoParaUpdate) {
            _.set(nuevoEstado.domain.update, pathAtributo, valorPorSetear);
            return nuevoEstado;
        }

        if (sonValoresDiferentes && !existeAtributoEnEstadoParaUpdate) {
            nuevoEstado.domain.nroUpdates++;
            _.set(nuevoEstado.domain.update, pathAtributo, valorPorSetear);
            return this.actualizarBotonAccion(nuevoEstado);
        }

        if (!sonValoresDiferentes && existeAtributoEnEstadoParaUpdate) {
            nuevoEstado.domain.nroUpdates--;
            _.unset(nuevoEstado.domain.update, pathAtributo);
            return this.actualizarBotonAccion(nuevoEstado);
        }
 */
        //Los campos que aparecen y desaparecen por cambios en otros campos
        //se setean con su valor por defecto por tanto, allí no hay
        // ningun cambio ni ningun update. Estos campos son tipoDeDoc y otros
        /* if (!sonValoresDiferentes && !existeAtributoEnEstadoParaUpdate) {
            // console.log("extraño");
            return nuevoEstado;
        } */

        /* if(valorPorSetear!==valorDelEstadoRegistro){
            if(valorDelEstadoParaUpdate===undefined) nuevoEstado.nroUpdates++;   
            //  _.set(nuevoEstado.update, pathAtributo,true);
            
            _.set(Estado.ParaUpdate, pathAtributo, valorPorSetear);
            return this.actualizarBotonAccion(nuevoEstado);
        }else{
            if(valorDelEstadoParaUpdate===undefined) return nuevoEstado;
            nuevoEstado.nroUpdates--;
            _.unset(Estado.ParaUpdate, pathAtributo);
            return this.actualizarBotonAccion(nuevoEstado);
        } */

        /* nuevoEstado.nroUpdates--;
        _.unset(Estado.ParaUpdate, pathAtributo);
        return nuevoEstado
        
        // let valorDelEstadoParaUpdate=_.get(Estado.ParaUpdate, pathAtributo);
        if(valorDelEstadoParaUpdate === undefined){
            nuevoEstado.nroUpdates++;
            _.set(nuevoEstado.update, pathAtributo,true);
        }
        _.set(Estado.ParaUpdate, pathAtributo, valorPorSetear);
        return this.actualizarBotonAccion(nuevoEstado);
         */

        /* if(nuevoEstado.flag.modoOperacion===ModosOp.GUARDAR){
            return nuevoEstado;
        }
        
        let esIgualAlDelEstadoAntiguo = _.get(nuevoEstado.domain, pathAtributo)===_.get(estado.domain,pathAtributo) 
        if(esIgualAlDelEstadoAntiguo){
            return nuevoEstado;
        }
        
        //Hubo Cambio de Valor
        let esIgualAlRegistrado = _.get(nuevoEstado.domain, pathAtributo)===_.get(Estado.Registrado,pathAtributo)        
        if(esIgualAlRegistrado){
            _.set(nuevoEstado.update, pathAtributo,false);
            nuevoEstado.nroUpdates--;
            _.unset(Estado.ParaUpdate, pathAtributo);
            return this.actualizarBotonAccion(nuevoEstado);
        }
        
        //Hubo Cambio de Valor el cual es diferente al Registrado
        _.set(Estado.ParaUpdate, pathAtributo, _.get(nuevoEstado.domain, pathAtributo));
        
        if(!_.get(nuevoEstado.update, pathAtributo)){
                _.set(nuevoEstado.update, pathAtributo,true);
                nuevoEstado.nroUpdates++;           
                return this.actualizarBotonAccion(nuevoEstado);
        }

        return nuevoEstado;
        
        // return this.actualizarBotonAccion(nuevoEstado, esIgualAlRegistrado); */
    // }
}
