
const EstadosClienteAFacturar = {
    HAB: "Habilitado",
    DHAB: "En Baja",
};

const TipoDeDoc = {
    DNI: "DNI",
    PASS: "PASS",
    PTP: "PTP",
    CE: "CE",
};

const TipoDeCliente = {
    DocId: "DocId",
    RUC: "RUC",
};

export { EstadosClienteAFacturar, TipoDeDoc, TipoDeCliente };
