//Se tiene que eliminar este file, luego de cargar las acciones en sus propios archivos

import ActionTypeFact from './ActionTypeFact'

export class SetCobranzaModoCobro extends ActionTypeFact {}
export class SetCobranzaTipoComprobante extends ActionTypeFact {}
export class SetCobranzaCicloFact extends ActionTypeFact {}

export class SetPersonaContactoValorListaNombres extends ActionTypeFact {}
export class SetPersonaContactoValorListaNombresOperaciones extends ActionTypeFact {}
export class SetPersonaContactoListaNombres extends ActionTypeFact {}
export class SetPersonaContactoAtributosNombre extends ActionTypeFact {}
export class SetPersonaContactoAtributosRol extends ActionTypeFact {}
export class SetPersonaContactoAtributosTelcel1 extends ActionTypeFact {}
export class SetPersonaContactoAtributosTelcel2 extends ActionTypeFact {}
export class SetPersonaContactoAtributosTelfijo extends ActionTypeFact {}
export class SetPersonaContactoAtributosEmail extends ActionTypeFact {}

export class SetPersonasContactoActNombre extends ActionTypeFact {}
export class SetPersonasContactoActRol extends ActionTypeFact {}
export class SetPersonasContactoActTelcel1 extends ActionTypeFact {}
export class SetPersonasContactoActTelcel2 extends ActionTypeFact {}
export class SetPersonasContactoActTelfijo extends ActionTypeFact {}
export class SetPersonasContactoActEmail extends ActionTypeFact {}

export class EliminarContactoAct extends ActionTypeFact {}

// export class GuardarClienteAFacturar extends ActionTypeFact {}
// export class CargarDataRegistradaDeClienteAFacturar extends ActionTypeFact {}
// export class CargarArrayErroresEnClienteAFacturar extends ActionTypeFact {}
// export class CargarErrorComunicEnClienteAFacturar extends ActionTypeFact {}
