import { useState, useRef } from "react";

import PantallaLogin from "../pantallas/PantallaLogin";
// import login from "../../servicios/login";
import ServicioDeLogin from "../../servicios/ServicioDeLogin";
// import * as Evento from "../../../comun/Eventos";
import * as TipoDeError from "../../../_comun/Errores";
import { NotificacionToastDeError } from "../../../_comun/Notificaciones";
import CodigoDeError from "../../../_comun/CodigosDeErrorDeRespuestas.json";
import DataRespuestas from "../../../_comun/DataEstaticaDeRespuestas.json";

const PresentadorLogueo = ({ cargarPantallaPrincipal }) => {
    // const [evaluacion, setEvaluacion] = useState(null);
    const [nombreUsuario, setNombreUsuario] = useState("");
    const [contraseña, setContraseña] = useState("");
    const [loading, setLoading] = useState(false);

    const [banderasError, setBanderasError] = useState({
        usuNoVal: false,
        nomVacio: false,
        contraVacia: false,
    });

    const refPantallaLoguin = useRef(null);
    // const [evaluacion, setEvaluacion] = useState({clienteNoValido:false, usuarioVacio:false, contraseñaVacia:false});
    //console.log("entro presentador logueo?")

    const manejarLogueo = async () => {
        setLoading(true);

        try {
            const usuario = await ServicioDeLogin({
                nombreUsuario,
                contraseña,
            });
            cargarPantallaPrincipal(usuario);
        } catch (error) {
            if (error instanceof TipoDeError.ListaDeErroresDelDominio) {
                // const dataDeRespuestaErronea = error.data;
                const nuevoEstado = JSON.parse(JSON.stringify(banderasError));
                for (const property in nuevoEstado) {
                    nuevoEstado[property] = false;
                }

                error.data.errores.forEach((error) => {
                    nuevoEstado[error.key] = true;
                });

                setLoading(false);
                setBanderasError(nuevoEstado);
                return
            }
            if (error instanceof TipoDeError.ErrorDeLaCapaDeServicios) {
                setLoading(false);
                setBanderasError({
                    usuNoVal: false,
                    nomVacio: false,
                    contraVacia: false,
                    // errorServidor: true,
                });

                let nombreDeError = error.constructor.name;
                refPantallaLoguin.current.notificar(
                    new NotificacionToastDeError(
                        DataRespuestas[nombreDeError].titulo,
                        DataRespuestas.labelCodigoError,
                        CodigoDeError[nombreDeError],
                        DataRespuestas[nombreDeError].descripcion
                    )
                );
                return
            }
        }

        /* return;

        const [usuario, codigoErrorAPI, error] = await login({
            nombreUsuario,
            contraseña,
        });

        setLoading(false);
        if (usuario) {
            // notificarEventoDeSeguridad(new Evento.Logueado(usuario));
        }

        if (codigoErrorAPI) {
            switch (codigoErrorAPI) {
                case "APIIA0001": {
                    setBanderasError({
                        usuNoVal: true,
                        nomVacio: false,
                        contraVacia: false,
                    });
                    break;
                }
                case "APIIA0002": {
                    setBanderasError({
                        usuNoVal: false,
                        nomVacio: true,
                        contraVacia: false,
                    });
                    break;
                }
                case "APIIA0003": {
                    setBanderasError({
                        usuNoVal: false,
                        nomVacio: false,
                        contraVacia: true,
                    });
                    break;
                }
                case "APIIA0004": {
                    setBanderasError({
                        usuNoVal: false,
                        nomVacio: true,
                        contraVacia: true,
                    });
                    break;
                }
                case "APIIA0005": {
                    setBanderasError({
                        usuNoVal: false,
                        nomVacio: false,
                        contraVacia: false,
                    });
                    break;
                }
                case "APIIA0006": {
                    setBanderasError({
                        usuNoVal: false,
                        nomVacio: false,
                        contraVacia: false,
                    });
                    break;
                }
                default:
            }
        }

        if (error) {
            setBanderasError({
                usuNoVal: false,
                nomVacio: false,
                contraVacia: false,
                // errorServidor: true,
            });
            /* refPantallaLoguin.current.notificarEvento(
                new Evento.Error(Error.ErrorNoConex.name)
            ); 
            // console.log(errorApp);
        } 
        */
        // setEvaluacion({clienteNoValido:false, usuarioVacio:true, contraseñaVacia:false})
        // console.log("salio de Manejar Logueo");
    };

    const setearNombreUsuario = (nombreUsuario) => {
        setNombreUsuario(nombreUsuario);
        // setBanderasError({ ...banderasError, usuarioVacio: false });
    };
    const setearContraseña = (contraseña) => {
        setContraseña(contraseña);
        // setBanderasError({ ...banderasError, contraseñaVacia: false });
    };

    return (
        <PantallaLogin
            nombreUsuario={nombreUsuario}
            contraseña={contraseña}
            loading={loading}
            banderasError={banderasError}
            setearNombreUsuario={setearNombreUsuario}
            setearContraseña={setearContraseña}
            manejarLogueo={manejarLogueo}
            ref={refPantallaLoguin}
        />
    );
};

export default PresentadorLogueo;
