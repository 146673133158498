// import DER from "./DataEstaticaDeRespuestas.json";
// import * as Evento from "./Eventos";
import {
    NotificacionToastDeCreacion,
    NotificacionToastDeError,
} from "./Notificaciones";

const NotificacionFlotante = (toast, notificacion) => {
    if (notificacion instanceof NotificacionToastDeCreacion) {
        toast.current.show({
            severity: "success",
            summary: notificacion.titulo,
            detail: notificacion.mensaje + " " + notificacion.id,
            /* summary: DER[Evento.Creado.name].titulo,
            detail:
                DER[Evento.Creado.name][notificacion.data.tipoDeEntidad] +
                notificacion.data.id, */
            life: 6000,
        });
    }
    if (notificacion instanceof NotificacionToastDeError) {
        // let val = DER[TipoDeEvento.Error][evento.data.tipoDeError].mensaje;
        function detallesError() {
            return (
                <div>
                    {notificacion.labelCodigoError+notificacion.codigoError}
                    <br />
                    {notificacion.mensaje}
                </div>
            );
        }
        toast.current.show({
            severity: "error",
            summary: notificacion.titulo,
            // detail: "",
            detail: detallesError(),
            life: 6000,
        });
    }
};

export default NotificacionFlotante;
