import { TipoDeCliente, TipoDeDoc } from "../Constantes";
import { ModosOp} from "../../_principal/Constantes";

//Si no hay input para el Dialogo de Cliente a Facturar
const getEstadoInicial = () => {
    return {
        domain: {
            screen:{
                idCliente: "-------",
                fechaReg: "--/--/--",
                estado: "-------",

                codigoCliente: "",
                tipoCliente: TipoDeCliente.DocId,
                tipoDoc: TipoDeDoc.DNI,
                nroDoc: "",
                nombres: "",
                apellidos: "",
                sexo: "",
                razonSocial: "",
                ruc: "",

                general: {
                    telcel1: "",
                    telcel2: "",
                    telfijo: "",
                    email: "",
                    sitioWeb: "",
                },

                cobranza: {
                    modoCobro: "cliente",
                    tipoComprobante: "bol",
                    cicloFact: "udiam",
                    controlTipoComprobante: true,
                    controlCicloFact: true,
                },

                contactos: [],
            },
            cargado: {}, //Data de una entidad cargada en el dialogo desde la Base de Datos
            update: {}, //Solo tiene data temporal del dominio va a modificar una entidad
            nroUpdates: 0,
        },

        /* domain: {
            idCliente: "-------",
            fechaReg: "--/--/--",
            estado: "-------",

            codigoCliente: "",
            tipoCliente: TipoDeCliente.DocId,
            tipoDoc: TipoDeDoc.DNI,
            nroDoc: "",
            nombres: "",
            apellidos: "",
            sexo: "",
            razonSocial: "",
            ruc: "",

            general: {
                telcel1: "",
                telcel2: "",
                telfijo: "",
                email: "",
                sitioWeb: "",
            },

            cobranza: {
                modoCobro: "cliente",
                tipoComprobante: "bol",
                cicloFact: "udiam",
                controlTipoComprobante: true,
                controlCicloFact: true,
            },

            contactos: [],
        }, */
        error: {
            nroDocBlanc: false,
            nroDocMF: false,
            nomBlanc: false,
            nomMF: false,
            apellBlanc: false,
            apellMF: false,
            sexoBlanc: false,
            rsocialBlanc: false,
            rsocialMF: false,
            rucBlanc: false,
            rucMF: false,
            telcel1Blanc: false,
            telcel1MF: false,
            telcel2MF: false,
            telfijoMF: false,
            emailMF: false,
            sitioWebMF: false,
        },
        flag: {
            modoOperacion: ModosOp.CREAR,
            procesandoComando: false,
            modal: false,
            cargandoEntidad: false,
            // iniciando: true
            // sensorDeAccionGuardar: false,
            // sensorDeAccionActualizar: false,
        },
        personasContacto: {
            contactosOrig: [],
            contactosAct: [],
            valorListaNombres: { nombre: "Definir Nuevo" },
            listaNombres: [{ nombre: "Definir Nuevo" }],
            atributos: {
                nombre: "Definir Nuevo",
                rol: "",
                telcel1: "",
                telcel2: "",
                telfijo: "",
                email: "",
            },
            flagControNombreSelected: false,
        },
        /* update: {
            codigoCliente: false,
            tipoCliente: false,
            tipoDoc: false,
            nroDoc: false,
            nombres: false,
            apellidos: false,
            sexo: false,
            razonSocial: false,
            ruc: false,

            general: {
                telcel1: false,
                telcel2: false,
                telfijo: false,
                email: false,
                sitioWeb: false,
            },

        }, */
    };
};

const Registrado = {
    /* codigoCliente: "",
    tipoCliente: "",
    tipoDoc: "",
    nroDoc: "",
    nombres: "",
    apellidos: "",
    sexo: "",
    razonSocial: "",
    ruc: "",

    general: {
        telcel1: "",
        telcel2: "",
        telfijo: "",
        email: "",
        sitioWeb: "",
    },

    cobranza: {
        modoCobro: "cliente",
        tipoComprobante: "bol",
        cicloFact: "udiam",
    },

    personasContacto: {
        contactosOrig: [],
        contactosAct: [],
    }, */
};

export default getEstadoInicial;
