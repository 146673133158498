import ActionClienteSet from "../ActionClienteSet";
import { TipoDeCliente, TipoDeDoc } from "../../../Constantes";


export default class SetTipoCliente extends ActionClienteSet{

    darleUnNuevoValorALaPropiedadDelaNuevaEntidad(estado, nuevoEstado) {
        nuevoEstado.domain.screen.tipoCliente = this.payload;
        if (this.payload === TipoDeCliente.DocId ) {
            new ActionClienteSet("tipoDoc",TipoDeDoc.DNI).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("nroDoc","").ejecutar(estado, nuevoEstado);
            new ActionClienteSet("apellidos","").ejecutar(estado, nuevoEstado);
            new ActionClienteSet("nombres","").ejecutar(estado, nuevoEstado);
            new ActionClienteSet("sexo","").ejecutar(estado, nuevoEstado);
        } else {
            new ActionClienteSet("razonSocial","").ejecutar(estado, nuevoEstado);
            new ActionClienteSet("ruc","").ejecutar(estado, nuevoEstado);
        }
        // this.cargarElRestoDeCamposAlCambiarTipoCliente(estado, nuevoEstado);
    }

    hacerALaPropiedadIgualASuVersionCargadaCuandoNoloEraAntes(estado, nuevoEstado) {
        nuevoEstado.domain.screen.tipoCliente = estado.domain.cargado.tipoCliente;
        delete nuevoEstado.domain.update.tipoCliente;
        if (estado.domain.cargado.tipoCliente === TipoDeCliente.DocId ) {
            delete nuevoEstado.domain.update.razonSocial;
            delete nuevoEstado.domain.update.ruc;
            new ActionClienteSet("tipoDoc",estado.domain.screen.tipoDoc).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("nroDoc",estado.domain.screen.nroDoc).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("apellidos",estado.domain.screen.apellidos).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("nombres",estado.domain.screen.nombres).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("sexo",estado.domain.screen.sexo).ejecutar(estado, nuevoEstado);
        } else {
            delete nuevoEstado.domain.update.tipoDoc;
            delete nuevoEstado.domain.update.nroDoc;
            delete nuevoEstado.domain.update.apellidos;
            delete nuevoEstado.domain.update.nombres;
            delete nuevoEstado.domain.update.sexo;
            new ActionClienteSet("razonSocial",estado.domain.screen.razonSocial).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("ruc",estado.domain.screen.ruc).ejecutar(estado, nuevoEstado);
        }
    }

    hacerALaPropiedadDiferenteDeSuVersionCargadaPorPrimeraVez(estado, nuevoEstado) {
        nuevoEstado.domain.screen.tipoCliente = this.payload;
        nuevoEstado.domain.update.tipoCliente = this.payload;
        if (this.payload === TipoDeCliente.DocId ) {
            delete nuevoEstado.domain.update.razonSocial;
            delete nuevoEstado.domain.update.ruc;
            new ActionClienteSet("tipoDoc",estado.domain.screen.tipoDoc).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("nroDoc",estado.domain.screen.nroDoc).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("apellidos",estado.domain.screen.apellidos).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("nombres",estado.domain.screen.nombres).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("sexo",estado.domain.screen.sexo).ejecutar(estado, nuevoEstado);
        } else {
            delete nuevoEstado.domain.update.tipoDoc;
            delete nuevoEstado.domain.update.nroDoc;
            delete nuevoEstado.domain.update.apellidos;
            delete nuevoEstado.domain.update.nombres;
            delete nuevoEstado.domain.update.sexo;
            new ActionClienteSet("razonSocial",estado.domain.screen.razonSocial).ejecutar(estado, nuevoEstado);
            new ActionClienteSet("ruc",estado.domain.screen.ruc).ejecutar(estado, nuevoEstado);
        }   
    }

    hacerALaPropiedadDiferenteDeSuVersionCargadaPorEnesimaVez(estado, nuevoEstado) {
        //Solo hay dos Tipos de Cliente ConDoc y Ruc, cuando haya un tercero implementaré este método
    }

    /* cargarElRestoDeCamposAlCambiarTipoCliente(estado, nuevoEstado){
        if (this.payload === TipoDeCliente.DocId ) {
            new SetTipoDoc(TipoDeDoc.DNI).ejecutar(estado, nuevoEstado);
            new SetNroDoc("").ejecutar(estado, nuevoEstado);
            new SetNombres("").ejecutar(estado, nuevoEstado);
            new SetApellidos("").ejecutar(estado, nuevoEstado);
            new SetSexo("").ejecutar(estado, nuevoEstado);
        } else {
            new SetRazonSocial("").ejecutar(estado, nuevoEstado);
            new SetNroRUC("").ejecutar(estado, nuevoEstado);
        }
    } */
    /* ejecutar(estado, nuevoEstado){

        nuevoEstado.domain.tipoCliente = this.payload;
        
        if (nuevoEstado.domain.tipoCliente === TipoDeCliente.DocId ) {
            new SetTipoDoc(TipoDeDoc.DNI).ejecutar(estado, nuevoEstado);
            new SetNroDoc("").ejecutar(estado, nuevoEstado);
            new SetNombres("").ejecutar(estado, nuevoEstado);
            new SetApellidos("").ejecutar(estado, nuevoEstado);
            new SetSexo("").ejecutar(estado, nuevoEstado);
        } else {
            new SetRazonSocial("").ejecutar(estado, nuevoEstado);
            new SetNroRUC("").ejecutar(estado, nuevoEstado);
        }

        //Efectos Colaterales
        return this.continuarCambiosEnEstado(estado, nuevoEstado,"tipoCliente");
    } */
}