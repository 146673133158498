const tiposCliente = [
    {label:"Con Doc. de Identidad", value:"DocId"}, 
    {label:"Con RUC", value:"RUC"}
]

const tiposDoc = [
    {label:"DNI", value:"DNI"},
    {label:"Pasaporte", value:"PASS"},
    {label:"PTP", value:"PTP"},
    {label:"Carnet de Extranjería", value:"CE"}
]

const tiposSexo = [
    {label:"Masculino", value:"M"}, 
    {label:"Femenino", value:"F"}
]

const modosCobro = [
    {label:"Exonerado", value:"exo"}, 
    {label:"Cliente", value:"cliente"}
]
const tiposComprobantes = [
    {label:"Recibo de Venta", value:"rv"}, 
    {label:"Factura", value:"fac"},
    {label:"Boleta", value:"bol"}
]
const ciclosFact = [
    {label:"15 del mes", value:"15m"}, 
    {label:"Último dia del mes", value:"udiam"}
]

export {tiposCliente, tiposDoc, tiposSexo, modosCobro, tiposComprobantes, ciclosFact};