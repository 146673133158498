import axios from "axios";
import * as Error from "../../_comun/Errores";
import { keyDeErrorApi, keyDeErrorLogin } from "../../_comun/Constantes";
import { StatusCodes } from "http-status-codes";

const ServicioDeQueryDeClientesAFacturar = async (paramsQuery) => {
    try {
        const respAxiosExito = await hacerRequerimientoAxios(paramsQuery);
        return getDataDeRespuestaAxiosExitosa(respAxiosExito);

    } catch (error) {
        if (error.hasOwnProperty("isAxiosError") && error.isAxiosError) {
            lanzarErrorDeRespuestaAxiosErronea(error);
        }
        if (error instanceof Error.ErrorDeProtocoloDelServidor) {
            throw error;
        }
    }
};

const hacerRequerimientoAxios = async (paramsQuery) => {
    const baseUrl =
        process.env.REACT_APP_API_SERVER + "/facturacion/clientesAFacturar";
    const respAxios = await axios({
        method: "get",
        url: baseUrl,
        data: paramsQuery,
        headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
    });
    return respAxios;
};

const getDataDeRespuestaAxiosExitosa = (respAxiosExito) => {
    const respuestaApi = respAxiosExito.data;
    let resultQueryDeClientes;
    try {
        //parsear respuestaApi de tal manera que la data tenga el formato de exito correcto
        //Si hay error en parseo:
        // throw new ErrorDeApp.ErrorFormatoDeCom();
        resultQueryDeClientes = respuestaApi.data;
    } catch (error) {
        throw new Error.ErrorDeProtocoloDelServidor();
    }
    return resultQueryDeClientes;
};

const lanzarErrorDeRespuestaAxiosErronea = (errorAxios) => {
    const respAxiosError = errorAxios.request;

    if (respAxiosError.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        //Se parsea respuestaApiComoString
        const respuestaApiComoString = respAxiosError.response;

        let respuestaApi = null;
        try {
            /* respuestaApi = JSON.parse(respuestaApiComoString);
            const errores = respuestaApi.data.erroresEnCliente;
            if (errores.length < 1 || errores.length > 20) {
                throw new Error("Error Parsing Login");
            } */
        } catch (error) {
            throw new Error.ErrorDeProtocoloDelServidor();
        }

        /* throw new Error.ListaDeErroresDelDominio({
            errores: respuestaApi.data.erroresEnCliente,
        }); */
    }

    if (respAxiosError.status === StatusCodes.BAD_REQUEST) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: “errBodyMF”, y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDelCliente();

        throw new Error.ErrorDeProtocoloDelCliente();
    }

    if (respAxiosError.status === StatusCodes.NOT_FOUND) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: "errPathMF", y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();

        throw new Error.ErrorDeProtocoloDelCliente();
    }

    if (respAxiosError.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: "errFlujoTrab", y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();

        throw new Error.ErrorDelFlujoDeTrabajoDelBackend();
    }

    // Para los restastes HTTP Codes se debe lanzar un ErrorDesconocidoDelServidor
    if (respAxiosError.status) {
        throw new Error.ErrorDeProtocoloDelServidor();
    }
    // Cuando no se puede conectar al servidor entonces respAxiosError.status === 0, debido a que no se recibio ninguna respuesta
    throw new Error.ErrorNoConex();
};

export default ServicioDeQueryDeClientesAFacturar;
