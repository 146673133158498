
class NotificacionToast{
    constructor(titulo, mensaje){
        this.titulo=titulo;
        this.mensaje=mensaje;
    }
}

class NotificacionToastDeError extends NotificacionToast{
    constructor(titulo, labelCodigoError, codigoError, mensaje){
        super(titulo,mensaje);
        this.labelCodigoError=labelCodigoError;
        this.codigoError=codigoError;
    }
}

class NotificacionToastDeCreacion extends NotificacionToast{
    constructor(titulo, mensaje,id){
        super(titulo,mensaje);
        this.id=id;
    }
}

export {NotificacionToastDeError, NotificacionToastDeCreacion};
