import React, { useRef } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

export default function BotonMenu({ label, icono, items }) {
    const menu = useRef(null);

    return (
        <>
            
            <Button
                // label={label}
                className="p-button-secondary p-button-text p-button-label py-1 px-4 grow-0 justify-center BotonMenu"
                onClick={(event) => menu.current.toggle(event)}
            >
                <i className={icono} style={{ marginRight: "0.6rem", fontSize: '1.1em'}} ></i>
                {/* <span className="leading-4">{label}</span> */}
                <span >{label}</span>
                <i
                    className="pi pi-chevron-down"
                    style={{ marginLeft: "0.6rem", fontSize: '0.8em'} }
                ></i>
            </Button>

            <Menu
                model={items}
                ref={menu}
                popup
                // baseZIndex={10}
                style={{ marginTop: "4px", marginLeft: "-3px" }}
                className={"w-fit"}
            />
        </>
    );
}
