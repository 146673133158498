import axios from "axios";
import * as Error from "../../_comun/Errores";
import { keyDeErrorApi } from "../../_comun/Constantes";
import { StatusCodes } from "http-status-codes";

const ServicioDeQueryDeUnClienteAFacturar  = async (
    idClienteAFactPorConsultar
) => {
    try {
        const respAxiosExito = await hacerRequerimientoAxios(
            idClienteAFactPorConsultar
        );
        return getDataDeRespuestaAxiosExitosa(respAxiosExito);
    } catch (error) {
        if (error.hasOwnProperty("isAxiosError") && error.isAxiosError) {
            lanzarErrorDeRespuestaAxiosErronea(error);
        }
        if (error instanceof Error.ErrorDeProtocoloDelServidor) {
            throw error;
        }
    }
};

const hacerRequerimientoAxios = async (idClienteAFactPorConsultar) => {
    const baseUrl =
        process.env.REACT_APP_API_SERVER +
        "/facturacion/clientesAFacturar/" +
        idClienteAFactPorConsultar;
    const respAxios = await axios({
        method: "get",
        url: baseUrl,
        data: null,
        headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
    });
    return respAxios;
};

const getDataDeRespuestaAxiosExitosa = (respAxiosExito) => {
    const respuestaApi = respAxiosExito.data;

    let dataDeActualizacionDeCliente;
    try {
        //parsear respuestaApi de tal manera que la data tenga el formato de exito correcto
        //Si hay error en parseo:
        // throw new ErrorDeApp.ErrorFormatoDeCom();
        dataDeActualizacionDeCliente = respuestaApi.data;
    } catch (error) {
        throw new Error.ErrorDeProtocoloDelServidor();
    }
    return dataDeActualizacionDeCliente;
};

const lanzarErrorDeRespuestaAxiosErronea = (errorAxios) => {
    const respAxiosError = errorAxios.request;

    const esCodigoHttpDelAPI =
        respAxiosError.status === StatusCodes.UNAUTHORIZED ||
        respAxiosError.status === StatusCodes.BAD_REQUEST ||
        respAxiosError.status === StatusCodes.UNPROCESSABLE_ENTITY ||
        respAxiosError.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (esCodigoHttpDelAPI) {
        const respuestaApiComoString = respAxiosError.response;

        let respuestaApi = null;
        try {
            //parsear respuestaApiComoString de tal manera que sea un objeto JSON aceptable
            respuestaApi = JSON.parse(respuestaApiComoString);

            //parsear respuestaApi de tal manera que existan las keys aceptables (errSis, codClientMF, y otros)
            //Si hay error:  throw new Error.ErrorFormatoDeCom();
        } catch (error) {
            throw new Error.ErrorDeProtocoloDelServidor();
        }

        const keyErrorRespuesta = respuestaApi.key;
        if (keyErrorRespuesta === keyDeErrorApi.ErroresDominio) {
            const keysDeErroresEnCliente =
                respuestaApi.data.erroresEnCliente.map((e) => {
                    return e.key;
                });
            throw new Error.ListaDeErroresDelDominio({
                errores: keysDeErroresEnCliente,
            });
        }
        if (keyErrorRespuesta === keyDeErrorApi.ErrorMalFormato) {
            throw new Error.ErrorDeProtocoloDelServidor();
        }
        if (keyErrorRespuesta === keyDeErrorApi.ErrorDelFlujoDeTrabajo) {
            throw new Error.ErrorDelFlujoDeTrabajoDelBackend();
        }
        if (keyErrorRespuesta === keyDeErrorApi.ErrorDesconocido) {
            throw new Error.ErrorDelFlujoDeTrabajoDelBackend();
        }
    }

    // Para los restastes HTTP Codes se debe lanzar un ErrorDesconocidoDelServidor
    if (respAxiosError.status) {
        throw new Error.ErrorDeProtocoloDelServidor();
    }

    // Cuando no se puede conectar al servidor entonces repuestaAxiosErrorStatus === 0, debido a que no se recibio ninguna respuesta
    throw new Error.ErrorNoConex();
};

export default ServicioDeQueryDeUnClienteAFacturar ;
