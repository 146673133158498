

import ActionTypePrincipal from "../../ActionTypePrincipal";

/**@class
 * @abstract
 */
export default class MostrarVentana extends ActionTypePrincipal {
    
    static getVentanaExistenteEnLaLista(state, ventanaAAbrir){

        const ventanaReemplazante = state.ventanas.find((ventana) => {
            if (ventana.puedeHacersePasarPor(ventanaAAbrir)){
                return true;
            } else {
                return false;
            }
        });
        if (ventanaReemplazante) {
            return ventanaReemplazante;
        } else {
           return null;
        }

    }
}