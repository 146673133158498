

export default function MenuItemsLogistica(dispatch) {
    return [
        {
            label: "Nueva Orden de Servicio",
            icon: "pi pi-fw pi-plus",
            // command: abrirVentanaCrearFia,
        },
        {
            label: "Listar Ordenes de Servicios",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
    ];
 

    
    
    
    
    
    //   return <Menubar model={items} />;
}

