import React, { useState, useContext, useReducer } from "react";
import GlobalContext from "../../../_comun/contexto/GlobalContext";
import DialogQueryClientesAFacturar from "../pantallas/DialogQueryClientesAFacturar";
import QueryFactReducer from "../../reductores/QueryFactReducer";
import CargaDeClientesBuscados from "../../reductores/acciones/dispatch/CargaDeClientesBuscados";
import CargaDeClienteConsultado from "../../reductores/acciones/dispatch/CargaDeClienteConsultado";
import FinalizarComando from "../../reductores/acciones/dispatch/FinalizarComando";

import * as TipoDeError from "../../../_comun/Errores";

import { NotificacionToastDeError } from "../../../_comun/Notificaciones";

import CodigoDeError from "../../../_comun/CodigosDeErrorDeRespuestas.json";
import DataDeRespuestas_Comun from "../../../_comun/DataEstaticaDeRespuestas.json";
import DataDeRespuestas_Facturacion from "../../servicios/DataEstaticaDeRespuestas.json";

import ServicioDeQueryDeClientesAFacturar from "../../servicios/ServicioDeQueryDeClientesAFacturar";
import ServicioDeQueryDeUnClienteAFacturar from "../../servicios/ServicioDeQueryDeUnClienteAFacturar";

const PresentadorQueryClientesAFacturar = ({ ventana }) => {
    
    const { notificar } = useContext(GlobalContext);

    const [estadoQ, dispatchQ] = useReducer(QueryFactReducer, {
        data: [],
        params:{
            
        },
        flag: {
            procesandoComando: false,
            procesandoBusquedaUnSoloCliente: false,
        },
    });

    const buscarClientesAFacturar = async () => {
        /* const clienteAFactPorActualizar = {
            id: estadoDCF.domain.screen.idCliente,
            data: estadoDCF.domain.update,
        }; */

        try {
            const respuestaQuery = await ServicioDeQueryDeClientesAFacturar({});
            dispatchQ(new CargaDeClientesBuscados(respuestaQuery.clientes));
        } catch (error) {
            /* if (error instanceof TipoDeError.ListaDeErroresDelDominio) {
                // const dataDeRespuestaErronea = error.data;
                dispatchQ(new Action.CargaDeErroresDelDominioAlActualizarCliente(error.data));
                return;
            }*/
            if (error instanceof TipoDeError.ErrorDeLaCapaDeServicios) {
                dispatchQ(new FinalizarComando());
                let nombreDeError = error.constructor.name;
                notificar(
                    new NotificacionToastDeError(
                        DataDeRespuestas_Comun[nombreDeError].titulo,
                        DataDeRespuestas_Comun.labelCodigoError,
                        CodigoDeError[nombreDeError],
                        DataDeRespuestas_Comun[nombreDeError].descripcion
                    )
                );
                return;
            }
        }
    };

    const buscarUnSoloClienteAFacturar = async (idCliente) => {
        try {
            const respuestaQuery = await ServicioDeQueryDeUnClienteAFacturar(idCliente);
            ventana.ventanaCargando.dispatch(new CargaDeClienteConsultado(respuestaQuery.cliente));
            // dispatchQ(new CargaDeClientesBuscados(respuestaQuery.clientes));
        } catch (error) {
            /* if (error instanceof TipoDeError.ListaDeErroresDelDominio) {
                // const dataDeRespuestaErronea = error.data;
                dispatchQ(new Action.CargaDeErroresDelDominioAlActualizarCliente(error.data));
                return;
            }*/
            if (error instanceof TipoDeError.ErrorDeLaCapaDeServicios) {
                dispatchQ(new FinalizarComando());
                let nombreDeError = error.constructor.name;
                notificar(
                    new NotificacionToastDeError(
                        DataDeRespuestas_Comun[nombreDeError].titulo,
                        DataDeRespuestas_Comun.labelCodigoError,
                        CodigoDeError[nombreDeError],
                        DataDeRespuestas_Comun[nombreDeError].descripcion
                    )
                );
                return;
            }
        }
    };

    return (
        <DialogQueryClientesAFacturar
            ventana={ventana}
            dispatchQ={dispatchQ}
            estadoQ={estadoQ}
            buscarClientesAFacturar={buscarClientesAFacturar}
            buscarUnSoloClienteAFacturar={buscarUnSoloClienteAFacturar}
        />
    );
};

export default PresentadorQueryClientesAFacturar;
