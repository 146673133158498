import axios from "axios";
// import * as ErrorServicio from './ErroresServicios'
import * as TipoDeError from "../../_comun/Errores";
import { keyDeErrorApi, keyDeErrorLogin } from "../../_comun/Constantes";
import { StatusCodes } from "http-status-codes";

const ServicioDeLogin = async (dataCliente) => {
    try {
        const respAxiosExito = await hacerRequerimientoAxios(dataCliente);
        return getDataDeRespuestaAxiosExitosa(respAxiosExito);
    } catch (error) {
        if (error.hasOwnProperty("isAxiosError") && error.isAxiosError) {
            lanzarErrorDeRespuestaAxiosErronea(error);
        }
        if (error instanceof TipoDeError.ErrorDeProtocoloDelServidor) {
            throw error;
        }
    }
};

const hacerRequerimientoAxios = async ({ nombreUsuario, contraseña }) => {
    const baseUrl = process.env.REACT_APP_API_SERVER + "/login";
    const respAxios = await axios({
        method: "post",
        url: baseUrl,
        data: {
            nombreUsuario: nombreUsuario,
            contraseña: contraseña,
        },
        headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
    });
    return respAxios;
};

const getDataDeRespuestaAxiosExitosa = (respAxiosExito) => {
    const respuestaApi = respAxiosExito.data;

    let dataDeCreacionDeCliente;
    try {
        //parsear respuestaApi de tal manera que la data tenga el formato de exito correcto
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();
        dataDeCreacionDeCliente = respuestaApi.data;
    } catch (error) {
        throw new TipoDeError.ErrorDeProtocoloDelServidor();
    }

    return dataDeCreacionDeCliente;
};

const lanzarErrorDeRespuestaAxiosErronea = (errorAxios) => {
    
    const respAxiosError = errorAxios.request;

    if (respAxiosError.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        //Se parsea respuestaApiComoString 
        const respuestaApiComoString = respAxiosError.response;
        
        let respuestaApi=null;
        try {
            respuestaApi = JSON.parse(respuestaApiComoString);
            const errores = respuestaApi.data.errores;
            if(errores.length<1 || errores.length>2){
                throw new TipoDeError("Error Parsing Login");
            }
            let usuNoValido=false, otros = 0;
            respuestaApi.data.errores.forEach((e) => {
                if (e.key === keyDeErrorLogin.usuarioNoVal) {
                    usuNoValido=true;
                }
                if (e.key === keyDeErrorLogin.nombreVacio) {
                    otros++;
                }
                if (e.key === keyDeErrorLogin.contraVacia) {
                    otros++;
                }
            });
            if(usuNoValido && otros>0) throw new TipoDeError("Error Parsing Login");
            if(!usuNoValido && otros===0) throw new TipoDeError("Error Parsing Login");

        } catch (error) {
            throw new TipoDeError.ErrorDeProtocoloDelServidor();
        }

        throw new TipoDeError.ListaDeErroresDelDominio({ errores: respuestaApi.data.errores });
    }

    if (respAxiosError.status === StatusCodes.BAD_REQUEST) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: “errBodyMF”, y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDelCliente();

        throw new TipoDeError.ErrorDeProtocoloDelCliente();
    }

    if (respAxiosError.status === StatusCodes.NOT_FOUND) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: "errPathMF", y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();

        throw new TipoDeError.ErrorDeProtocoloDelCliente();
    }

    if (respAxiosError.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: "errFlujoTrab", y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();

        throw new TipoDeError.ErrorDelFlujoDeTrabajoDelBackend();
    }

    // Para los restastes HTTP Codes se debe lanzar un ErrorDesconocidoDelServidor
    if (respAxiosError.status) {
        throw new TipoDeError.ErrorDeProtocoloDelServidor();
    }
    // Cuando no se puede conectar al servidor entonces respAxiosError.status === 0, debido a que no se recibio ninguna respuesta
    throw new TipoDeError.ErrorNoConex();
};

export default ServicioDeLogin;
