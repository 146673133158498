import ActionTypeFact from "../ActionTypeFact";

export default class ActualizarClienteAFacturar extends ActionTypeFact {

    ejecutar(estado, nuevoEstado) {    
        nuevoEstado.flag.procesandoComando = true;
        /* nuevoEstado.flag.sensorDeAccionActualizar = !(estado.flag.sensorDeAccionActualizar); */
        return nuevoEstado;
    }

}