import React, { useContext, useState, useRef, forwardRef, useImperativeHandle} from "react";
import GlobalContext from "../../../_comun/contexto/GlobalContext";
import BarraMenu from "./BarraMenu";
import { Toast } from "primereact/toast";
import NotificacionFlotante from '../../../_comun/NotificacionFlotante'
// import { Dropdown } from "primereact/dropdown";
// import ActionNotificarEvento from "../../reductores/ActionNotificarEvento";

const PantallaPrincipal = forwardRef((props, ref) => {
    const { lista_ventanas } = useContext(GlobalContext);
    // lista_componentes_ventanas = lista_ventanas;
    const toast = useRef(null);

    useImperativeHandle(ref, () => ({
        notificar(notificacion) {
            NotificacionFlotante(toast,notificacion);
        }
    }));

    // const [modoOperacion, setModoOperacion] = useState();

    // const tiposCliente = [
    //     {label:"Con Doc. de Identidad", value:"DOCID"},
    //     {label:"Con RUC", value:"RUC"}
    // ]
    
    return (
        <>
            <BarraMenu />
            {lista_ventanas}
            <Toast ref={toast} position="bottom-right" />
            {/* <Dropdown
                value={cliente.tipoDeCliente}
                options={tiposCliente}
                onChange={(e) => setCliente({...cliente, tipoDeCliente:e.value})}
                // onChange={cambiarCliente}
                // onClick={enfocar}
                // onMouseDown={enfocar}
                // onFocus={enfocar}
                /* onChange={(e) =>
                setCliente({
                    ...cliente,
                    tipoDeCliente: e.value,
                })
            } 
                // optionLabel="name"
                // placeholder="Selecciona un Tipo"
                //className="p-0 w-full rounded-none"/> 
            */}
        </>
    );
});

export default PantallaPrincipal;
