import React, { useContext, useReducer } from "react";
import GlobalContext from "../../../_comun/contexto/GlobalContext";
import DialogoClienteAFacturar from "../pantallas/DialogClienteAFacturar";

import FactReducer from "../../reductores/FactReducer";
import * as Action from "../../reductores/acciones/dispatch/_ActionsDispatch";

import Estado from "../../reductores/EstadoDialogoClienteAFacturar";
import { TipoDeCliente } from "../../Constantes";
import * as TipoDeError from "../../../_comun/Errores";

import ServicioRegistroDeClienteAFacturar from "../../servicios/ServicioRegistroDeClienteAFacturar";
import ServicioDeActualizacionDeClienteAFacturar from "../../servicios/ServicioDeActualizacionDeClienteAFacturar";

import {
    NotificacionToastDeCreacion,
    NotificacionToastDeError,
} from "../../../_comun/Notificaciones";

import CodigoDeError from "../../../_comun/CodigosDeErrorDeRespuestas.json";
import DataDeRespuestas_Comun from "../../../_comun/DataEstaticaDeRespuestas.json";
import DataDeRespuestas_Facturacion from "../../servicios/DataEstaticaDeRespuestas.json";
import { ModosOp } from "../../../_comun/Constantes";
import EsperarClienteConsultado from "../../reductores/acciones/dispatch/EsperarClienteConsultado";

const PresentadorClienteAFacturar = ({ ventana }) => {
    const {
        // estadoPrin,
        // dispatchPrincipal,
        notificar,
    } = useContext(GlobalContext);

    //actualiza estadoInicial de la entrada si hay algun input
    /* let funcionEstadoInicial = null;
    if(ventana.estadoCom.flag.modoOperacion === ModosOp.CREAR){
        funcionEstadoInicial = Estado.getEstadoInicial;
    }
    if(ventana.estadoCom.flag.cargandoEntidad){
        const estadoInicial = Estado.getEstadoInicial();
        funcionEstadoInicial = () =>{
            estadoInicial.flag.modal=
        }
    }
    const [estadoDCF, dispatchDCF] = useReducer(FactReducer, null, funcionEstadoInicial); */
    // const [estadoDCF, dispatchDCF] = useReducer(FactReducer, estadoInicial);
    // const [estadoDCF, dispatchDCF] = useReducer(FactReducer, Estado.getEstadoInicial());
    // const estadoDCF = ventana.estadoDCF;
    
    /* if (
        estadoDCF.flag.iniciando &&
        ventana.estadoCom.flag.modoOperacion !== ModosOp.CREAR
        ) {
            const estadoInicial = ventana.estadoCom
            ventana.estadoCom = estadoDCF;
            ventana.dispatch = dispatchDCF;
            dispatchDCF(new EsperarClienteConsultado(estadoInicial));
            return;
        } */
        
    const [estadoDCF, dispatchDCF] = useReducer(FactReducer, ventana.estadoCom);
    ventana.estadoCom = estadoDCF;
    ventana.dispatch = dispatchDCF;

    const registrarClienteAFacturar = async () => {
        let bloqueIdentificacion;
        if (estadoDCF.domain.screen.tipoCliente === TipoDeCliente.DocId) {
            bloqueIdentificacion = {
                tipoDoc: estadoDCF.domain.screen.tipoDoc,
                nroDoc: estadoDCF.domain.screen.nroDoc,
                nombres: estadoDCF.domain.screen.nombres,
                apellidos: estadoDCF.domain.screen.apellidos,
                sexo: estadoDCF.domain.screen.sexo,
            };
        } else {
            bloqueIdentificacion = {
                razonSocial: estadoDCF.domain.screen.razonSocial,
                ruc: estadoDCF.domain.screen.ruc,
            };
        }
        const clienteAFact = {
            tipoCliente: estadoDCF.domain.screen.tipoCliente,
            ...bloqueIdentificacion,

            general: {
                telcel1: estadoDCF.domain.screen.general.telcel1,
                telcel2: estadoDCF.domain.screen.general.telcel2,
                telfijo: estadoDCF.domain.screen.general.telfijo,
                email: estadoDCF.domain.screen.general.email,
                sitioWeb: estadoDCF.domain.screen.general.sitioWeb,
            },
            /*
            cobranza: {
                modoCobro: estadoDCF.modoCobro,
                tipoComprobante: estadoDCF.tipoComprobante,
                cicloFact: estadoDCF.cicloFact,
            },
            
            personasContacto: estadoDCF.personasContacto.contactosAct, */
        };
        // Object.setPrototypeOf(clienteAFact, ClienteAFacturar.prototype);

        try {
            const dataRegistradaDeClienteAFacturar = await ServicioRegistroDeClienteAFacturar(
                clienteAFact
            );
            dispatchDCF(new Action.CargaDeClienteRegistrado(dataRegistradaDeClienteAFacturar));
            notificar(
                new NotificacionToastDeCreacion(
                    DataDeRespuestas_Facturacion.CreacionClienteAFacturar.titulo,
                    DataDeRespuestas_Facturacion.CreacionClienteAFacturar.mensaje,
                    dataRegistradaDeClienteAFacturar.idCliente
                )
            );
        } catch (error) {
            if (error instanceof TipoDeError.ListaDeErroresDelDominio) {
                dispatchDCF(new Action.CargaDeErroresDelDominioAlCrearCliente(error.data));
                return;
            }
            if (error instanceof TipoDeError.ErrorDeLaCapaDeServicios) {
                dispatchDCF(new Action.FinalizarComando());

                let nombreDeError = error.constructor.name;
                notificar(
                    new NotificacionToastDeError(
                        DataDeRespuestas_Comun[nombreDeError].titulo,
                        DataDeRespuestas_Comun.labelCodigoError,
                        CodigoDeError[nombreDeError],
                        DataDeRespuestas_Comun[nombreDeError].descripcion
                    )
                );
                return;
            }
        }
    };

    const actualizarClienteAFacturar = async () => {
        const clienteAFactPorActualizar = {
            id: estadoDCF.domain.screen.idCliente,
            data: estadoDCF.domain.update,
        };

        try {
            // const dataDeClienteActualizado =
            await ServicioDeActualizacionDeClienteAFacturar(clienteAFactPorActualizar);
            dispatchDCF(
                new Action.CargaDeClienteActualizado()
                // new Action.CargaDeClienteActualizado(dataDeClienteActualizado)
            );
        } catch (error) {
            if (error instanceof TipoDeError.ListaDeErroresDelDominio) {
                // const dataDeRespuestaErronea = error.data;
                dispatchDCF(new Action.CargaDeErroresDelDominioAlActualizarCliente(error.data));
                return;
            }
            if (error instanceof TipoDeError.ErrorDeLaCapaDeServicios) {
                dispatchDCF(new Action.FinalizarComando());
                let nombreDeError = error.constructor.name;
                notificar(
                    new NotificacionToastDeError(
                        DataDeRespuestas_Comun[nombreDeError].titulo,
                        DataDeRespuestas_Comun.labelCodigoError,
                        CodigoDeError[nombreDeError],
                        DataDeRespuestas_Comun[nombreDeError].descripcion
                    )
                    /* new NotificacionToastDeError(
                        DataDeRespuestas_Comun[nombreDeError].titulo,
                        DataDeRespuestas_Comun.labelCodigoError +
                            CodigoDeError[nombreDeError] +
                            "\n" +
                            DataDeRespuestas_Comun[nombreDeError].descripcion
                    ) */
                );
                return;
            }
        }
    };

    return (
        <DialogoClienteAFacturar
            ventana={ventana}
            estadoDCF={estadoDCF}
            dispatchDCF={dispatchDCF}
            registrarClienteAFacturar={registrarClienteAFacturar}
            actualizarClienteAFacturar={actualizarClienteAFacturar}
        />
    );
};

export default PresentadorClienteAFacturar;
