export class ErrorDeDominio extends Error {}

export class ErrorDeLaCapaDeServicios extends Error {}

export class ListaDeErroresDelDominio extends ErrorDeLaCapaDeServicios {
    constructor(data) {
        super();
        this.data = data;
    }
}

export class ErrorDeProtocoloDelServidor extends ErrorDeLaCapaDeServicios {}

export class ErrorDeProtocoloDelCliente extends ErrorDeLaCapaDeServicios {}

export class ErrorDelFlujoDeTrabajoDelBackend extends ErrorDeLaCapaDeServicios {}

export class ErrorNoConex extends ErrorDeLaCapaDeServicios {}

//DESFASADOS
export class ErrorRepuestaDesconocidaDelServidor extends ErrorDeLaCapaDeServicios {}

export class ErrorDesconocidoDelBackend extends ErrorDeLaCapaDeServicios {}
