import ActionTypeFact from "../ActionTypeFact";
import {ModosOp} from '../../../../_principal/Constantes'


export default class CargaDeClienteConsultado extends ActionTypeFact {

    ejecutar(estado, nuevoEstado) {
        // nuevoEstado.domain.idCliente = this.payload.idCliente;
        // nuevoEstado.domain.fechaReg = this.payload.fechaReg;
        // nuevoEstado.domain.estado = this.payload.estado;
        // nuevoEstado.flag.procesandoComando = false;
        // nuevoEstado.flag.modoOperacion = ModosOp.OK;
        // Estado.Registrado = estado.domain;
        // Estado.ParaUpdate={};
        nuevoEstado.flag.modal = false;
        nuevoEstado.flag.cargandoEntidad = false;
        nuevoEstado.domain.screen = {...estado.domain.screen, ...this.payload};
        nuevoEstado.domain.cargado = JSON.parse(JSON.stringify(this.payload));
        nuevoEstado.domain.update = {}
        nuevoEstado.domain.nroUpdates= 0;

        return nuevoEstado;
    }

}