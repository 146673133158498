import ActionTypeFact from "../ActionTypeFact";

export default class GuardarClienteAFacturar extends ActionTypeFact {

    ejecutar(estado, nuevoEstado) {    
        nuevoEstado.flag.procesandoComando = true;
        // nuevoEstado.flag.sensorDeAccionGuardar = !(estado.flag.sensorDeAccionGuardar);
        // nuevoEstado.flag.sensorDeAccionGuardar = this.payload;
        return nuevoEstado;
    }

}