// import { useState } from "react";
import { useRef, forwardRef, useImperativeHandle } from "react";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import NotificacionFlotante from "../../../_comun/NotificacionFlotante";

const PantallaLogin = forwardRef(
    (
        {
            nombreUsuario,
            contraseña,
            loading,
            banderasError,
            setearNombreUsuario,
            setearContraseña,
            manejarLogueo,
        },
        ref
    ) => {
        /* const manejarBotonSubmit = () => {
        manejarLogueo();
    }; */

        const toast = useRef(null);

        useImperativeHandle(ref, () => ({
            notificar(notificacion) {
                NotificacionFlotante(toast, notificacion);
            },
        }));

        let { usuNoVal, nomVacio, contraVacia } = banderasError;
        //   console.log(clienteNoValido,usuarioVacio,contraseñaVacia);

        return (
            <div className="w-screen h-screen flex justify-center content-center">
                <div className="flex flex-col mx-auto my-auto w-full max-w-xs">
                    <form className="bg-white shadow-md rounded px-4 pt-6 pb-6 mb-3">
                        <div className="mb-3">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="username"
                            >
                                Usuario
                            </label>
                            <input
                                className={`${
                                    nomVacio ? "border-red-500" : ""
                                } shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-base leading-tight mb-2 focus:outline-none focus:shadow-outline`}
                                id="username"
                                type="text"
                                placeholder="Nombre de Usuario"
                                valor={nombreUsuario}
                                onChange={({ target }) => {
                                    setearNombreUsuario(target.value);
                                }}
                            ></input>

                            <Message
                                severity="error"
                                text="¡Nombre de usuario vacio!"
                                className={`${
                                    nomVacio ? "" : "hidden"
                                } appearance-none bg-red-100 w-full py-1 px-2 mt-0 mb-1 leading-tight justify-start`}
                                // style={{backgroundColor:'#FEE2E2'}}
                            />
                        </div>

                        <div className="flex flex-column mb-3">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="password"
                            >
                                Contraseña
                            </label>
                            <input
                                className={`shadow appearance-none border ${
                                    contraVacia ? "border-red-500" : ""
                                } rounded w-full py-2 px-3 text-base text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline`}
                                id="password"
                                type="password"
                                placeholder="Ingresar Contraseña"
                                value={contraseña}
                                onChange={({ target }) => {
                                    setearContraseña(target.value);
                                }}
                            ></input>

                            <Message
                                severity="error"
                                text="¡Contraseña vacía!"
                                className={`${
                                    contraVacia ? "" : "hidden"
                                } appearance-none bg-red-100 w-full py-1 px-2 mt-0 mb-1 leading-tight justify-start`}
                            />
                        </div>

                        {/* <div
                        className={`text-red-500 text-xs text-center italic ${
                            usuarioNoValido ? "" : "hidden"
                        } bg-red-100 appearance-none border border-red-300   
          rounded w-full py-2 px-3 mt-2 mb-2 leading-tight focus:outline-none focus:shadow-outline`}
                    >
                        Usuario no valido.
                    </div> */}

                        <Message
                            severity="error"
                            text="¡Usuario no valido!"
                            className={`${
                                usuNoVal ? "" : "hidden"
                            } bg-red-100 appearance-none w-full py-1 px-0 mt-1 mb-2 leading-tight`}
                        />

                        {/* <Message
                            severity="error"
                            text="¡Hubo un error en el Servidor!"
                            className={`${
                                errorServidor ? "" : "hidden"
                            } bg-red-100 appearance-none w-full py-1 px-0 mt-0 mb-2 leading-tight`}
                            // style={{backgroundColor:'#FEE2E2'}}
                        /> */}

                        {/* <div className="flex flex-column justify-center content-center">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={manejarBotonSubmit}
                            
                        >
                            Ingresar
                        </button>       
                    </div> */}

                        <Button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                            type="button"
                            label="Ingresar"
                            onClick={manejarLogueo}
                            loading={loading}
                            iconPos="right"
                        ></Button>
                    </form>
                    <p className="text-center text-gray-500 text-xs">
                        &copy;2022 Fullfiber. Todos los derechos reservados.
                    </p>
                </div>

                <Toast ref={toast} position="bottom-right" />
            </div>
        );
    }
);

export default PantallaLogin;
