import Ventana from "../../../_principal/reductores/ventanas/Ventana";
import { ModosOp } from "../../../_comun/Constantes";
import getEstadoInicial from "../EstadoDialogoClienteAFacturar";

export default class VentanaClienteAFacturar extends Ventana {
    constructor() {
        super();
        this.estadoCom = getEstadoInicial();
        /* this.estadoCom = {
            domain: {
                screen: {
                    idCliente: null,
                },
            },
            flag: {
                modoOperacion: ModosOp.CREAR,
                modal: false,
                cargandoEntidad: false,
            },
        }; */
        this.dispatch=null;
        // this.ventanaCargando=null
    }

    puedeHacersePasarPor(ventanaAAbrir) {
        if (!(ventanaAAbrir instanceof VentanaClienteAFacturar)) {
            return false;
        }

        if (
            this.estadoCom.flag.modoOperacion === ModosOp.CREAR &&
            ventanaAAbrir.estadoCom.flag.modoOperacion === ModosOp.CREAR
        ) {
            return true;
        }

        if (
            this.estadoCom.flag.modoOperacion !== ModosOp.CREAR &&
            ventanaAAbrir.estadoCom.flag.modoOperacion !== ModosOp.CREAR &&
            this.estadoCom.domain.screen.idCliente === ventanaAAbrir.estadoCom.domain.screen.idCliente
        ) {
            return true;
        }

        return false;
    }

    clonar(){
        const nuevaVentana = new VentanaClienteAFacturar();
        nuevaVentana.id              = this.id;
        nuevaVentana.posicionZ       = this.posicionZ      
        nuevaVentana.estadoCom       = this.estadoCom      
        nuevaVentana.dispatch        = this.dispatch       
        // nuevaVentana.ventanaCargando = this.ventanaCargando
        return nuevaVentana;

    }
}
