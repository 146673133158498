import React, { useState, useEffect, useCallback, useContext, useRef } from "react";

import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from 'primereact/skeleton';


import Dialogo from "../../../_comun/componentes/pantallas/Dialogo";
import GlobalContext from "../../../_comun/contexto/GlobalContext";
import EliminarVentana from "../../../_principal/reductores/acciones/widgets/EliminarVentana";

import * as AccionesFact from "../../reductores/acciones/AccionesTypeFact";
import * as Action from "../../reductores/acciones/dispatch/_ActionsDispatch";
import ActionClienteSet from "../../reductores/acciones/ActionClienteSet";
import ActionSetTipoCliente from "../../reductores/acciones/dispatch/SetTipoCliente";

import { EstadosClienteAFacturar, TipoDeDoc, TipoDeCliente } from "../../Constantes";
import * as ModelosDeCombos from "./ModelosDeCombos";

import { ModosOp } from "../../../_comun/Constantes";

const DialogoClienteAFacturar = ({
    ventana,
    estadoDCF,
    dispatchDCF,
    registrarClienteAFacturar,
    actualizarClienteAFacturar,
}) => {
    const { dispatchPrincipal } = useContext(GlobalContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const [banderaTabPersonasContacto, setBanderaTabPersonasContacto] = useState(false);

    const nomPersContactInput = useRef(null);
    const menuListaNombresPersContact = useRef(null);
    const isMounted = useRef(false);

    useEffect(
        () => {
            if (nomPersContactInput.current !== null) {
                nomPersContactInput.current.select();
                //nomPersContactInput.current.focus();
                // console.log("Dentro de UseEffect: ", nomPersContactInput);
            }

            /* 
            function handler(event) {
                event.stopPropagation();
                event.cancelBubble = true;
                return false;
            }
            // listaNombresPersContact.current.element.onclick = handler;
            // listaNombresPersContact.current.element.onmousedown = handler;
            // listaNombresPersContact.current.element.onmouseup = handler;
        } */
        },
        [
            // estadoDCF.personasContacto.flagControNombreSelected,
            // banderaTabPersonasContacto,
        ]
    );

    /* useEffect(() => {
        if (isMounted.current) {
            registrarClienteAFacturar();
        }
    }, [estadoDCF.flag.sensorDeAccionGuardar]);

    useEffect(() => {
        if (isMounted.current) {
            actualizarClienteAFacturar();
        } else {
            isMounted.current = true;
        }
    }, [estadoDCF.flag.sensorDeAccionActualizar]); */

    // const guardarClick = (eventoDom) => {
    //     console.log(eventoDom);
    //     dispatch(new EliminarVentana(ventana.id));
    //     //eventoDom.stopPropagation();
    // };

    const cancelarClick = (eventoSynDom) => {
        dispatchPrincipal(new EliminarVentana(ventana));
        eventoSynDom.stopPropagation();
    };

    const header = {
        elementoReact: <p className="p-2 bg-gradient-to-r from-purple-100">Cliente a Facturar</p>,
        enProgreso: estadoDCF.flag.procesandoComando,
    };

    const labelBotonAccion = (() => {
        // if (ventana.modoOp === ModosOp.CREAR) return "Guardar";
        // if (ventana.modoOp === ModosOp.OK) return "   OK    ";
        // if (ventana.modoOp === ModosOp.MODIFICAR) return "Actualizar";
        if (estadoDCF.flag.modoOperacion === ModosOp.CREAR) return "Guardar";
        if (estadoDCF.flag.modoOperacion === ModosOp.OK) return "   OK    ";
        if (estadoDCF.flag.modoOperacion === ModosOp.MODIFICAR) return "Actualizar";
    })();

    const footer = (
        <div>
            <Button
                label={labelBotonAccion}
                icon="pi pi-check"
                onClick={(eventoSynDom) => {
                    if (estadoDCF.flag.modoOperacion === ModosOp.CREAR) {
                        dispatchDCF(new Action.GuardarClienteAFacturar());
                        registrarClienteAFacturar();
                        return;
                    }
                    if (estadoDCF.flag.modoOperacion === ModosOp.MODIFICAR) {
                        dispatchDCF(new Action.ActualizarClienteAFacturar());
                        actualizarClienteAFacturar();
                        return;
                    }
                    if (estadoDCF.flag.modoOperacion === ModosOp.OK) {
                        cancelarClick(eventoSynDom);
                        return;
                    }
                }}
                loading={estadoDCF.flag.procesandoComando}
                iconPos="left"
                className="whitespace-pre"
            />
            <Button
                label="Cancelar"
                icon="pi pi-times"
                onClick={(eventoDom) => cancelarClick(eventoDom)}
                disabled={estadoDCF.flag.procesandoComando}
                // className="w-32"
            />
        </div>
    );

    let bloqueTipoCliente;

    if (estadoDCF.domain.screen.tipoCliente === TipoDeCliente.DocId) {
        bloqueTipoCliente = (
            <>
                <div className="mr-10">
                    <div className="flex mb-0.5">
                        <div className="w-32 shrink-0">Tipo de Cliente : </div>
                        <div>
                            <Dropdown
                                value={estadoDCF.domain.screen.tipoCliente}
                                options={ModelosDeCombos.tiposCliente}
                                onChange={(e) => {
                                    dispatchDCF(new ActionSetTipoCliente("tipoCliente", e.value));
                                }}
                                className="p-0 w-full rounded-none"
                                // panelClassName="p-0"
                                // panelStyle={{ padding: 0 }}
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                        </div>
                    </div>
                    <div className="flex mb-0.5">
                        <div className="w-32">Tipo de Doc. : </div>
                        <div>
                            <Dropdown
                                value={estadoDCF.domain.screen.tipoDoc}
                                options={ModelosDeCombos.tiposDoc}
                                onChange={(e) => {
                                    dispatchDCF(new ActionClienteSet("tipoDoc", e.target.value));
                                    // dispatchDCF(new Action.SetTipoDoc(e.value));
                                }}
                                // placeholder="Selecciona un Tipo"
                                className="p-0 w-full rounded-none"
                                // panelClassName="p-0"
                                // panelStyle={{ padding: 0 }}
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-32">N° de Doc. : </div>
                        <div>
                            <InputText
                                id="in"
                                value={estadoDCF.domain.screen.nroDoc}
                                className="p-0 w-24 rounded-none block p-error"
                                onChange={(e) => {
                                   console.log("entro"); 
                                    dispatchDCF(
                                        new ActionClienteSet("nroDoc", e.target.value)
                                        // new Action.SetNroDoc(e.target.value)
                                    );
                                }}
                                maxLength={
                                    estadoDCF.domain.screen.tipoDoc === TipoDeDoc.DNI ? 8 : 9
                                }
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                            <small
                                id=""
                                className={
                                    estadoDCF.error.nroDocBlanc ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                Se requiere el Número de Documento.
                            </small>
                            <small
                                id=""
                                className={
                                    estadoDCF.error.nroDocMF ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                El formato de N° de Doc. es incorrecto.
                            </small>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex mb-0.5">
                        <div className="w-24 shrink-0">Apellidos : </div>
                        <div className="pl-0">
                            <InputText
                                id="in"
                                value={estadoDCF.domain.screen.apellidos}
                                className="p-0 w-full rounded-none"
                                onChange={(e) => {
                                    dispatchDCF(new ActionClienteSet("apellidos", e.target.value));
                                    console.log("evento apellidos: ", e);
                                }}
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                            <small
                                id=""
                                className={
                                    estadoDCF.error.apellBlanc ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                Se requiere Apellidos del Cliente.
                            </small>
                            <small
                                id=""
                                className={
                                    estadoDCF.error.apellMF ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                El formato de los Apellidos es incorrecto.
                            </small>
                        </div>
                    </div>
                    <div className="flex mb-0.5">
                        <div className="w-24 shrink-0">Nombres : </div>
                        <div className="pl-0">
                            <InputText
                                id="in"
                                value={estadoDCF.domain.screen.nombres}
                                className="p-0 w-full rounded-none"
                                onChange={(e) =>
                                    dispatchDCF(
                                        new ActionClienteSet("nombres", e.target.value)
                                        // new Action.SetNombres(e.target.value)
                                    )
                                }
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                            <small
                                id=""
                                className={
                                    estadoDCF.error.nomBlanc ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                Se requiere Nombres del Cliente.
                            </small>
                            <small
                                id=""
                                className={
                                    estadoDCF.error.nomMF ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                El formato de los Nombres es incorrecto.
                            </small>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-24 shrink-0">Sexo : </div>
                        <div className="pl-0">
                            <Dropdown
                                value={estadoDCF.domain.screen.sexo}
                                options={ModelosDeCombos.tiposSexo}
                                onChange={
                                    (e) => dispatchDCF(new ActionClienteSet("sexo", e.target.value))
                                    // dispatchDCF(new Action.SetSexo(e.value))
                                }
                                placeholder="Seleccionar..."
                                className="p-0 w-full rounded-none"
                                // panelClassName="p-0"
                                // panelStyle={{ padding: 0 }}
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                            <small
                                id=""
                                className={
                                    estadoDCF.error.sexoBlanc ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                Se requiere elegir un Género.
                            </small>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        // Cliente Tipo RUC
        bloqueTipoCliente = (
            <>
                <div className="mr-10">
                    <div className="flex mb-0.5">
                        <div className="w-32 shrink-0">Tipo de Cliente : </div>
                        <div>
                            <Dropdown
                                value={estadoDCF.domain.screen.tipoCliente}
                                options={ModelosDeCombos.tiposCliente}
                                onChange={(e) => {
                                    // console.log(e);
                                    dispatchDCF(new ActionSetTipoCliente("tipoCliente", e.value));
                                }}
                                className="p-0 w-full rounded-none"
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                        </div>
                    </div>
                    <div className="flex mb-0.5">
                        <div className="w-32 shrink-0">Razón Social : </div>
                        <div className="pl-0">
                            <InputText
                                value={estadoDCF.domain.screen.razonSocial}
                                className="p-0 w-72 rounded-none"
                                onChange={(e) =>
                                    dispatchDCF(
                                        new ActionClienteSet("razonSocial", e.target.value)
                                        /* new Action.SetRazonSocial(
                                            e.target.value
                                        ) */
                                    )
                                }
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                            <small
                                id=""
                                className={
                                    estadoDCF.error.rsocialBlanc ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                Se requiere una Razón Social.
                            </small>
                            <small
                                id=""
                                className={
                                    estadoDCF.error.rsocialMF ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                El formato de Razón Social es incorrecto.
                            </small>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-32 shrink-0">N° de RUC : </div>
                        <div className="pl-0">
                            <InputText
                                value={estadoDCF.domain.screen.ruc}
                                className="p-0 w-32 rounded-none"
                                onChange={(e) =>
                                    dispatchDCF(
                                        new ActionClienteSet("ruc", e.target.value)
                                        // new Action.SetNroRUC(e.target.value)
                                    )
                                }
                                maxLength="11"
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                            <small
                                id=""
                                className={
                                    estadoDCF.error.rucBlanc ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                Se requiere un N° de RUC.
                            </small>
                            <small
                                id=""
                                className={
                                    estadoDCF.error.rucMF ? "mb-1.5 block p-error" : "hidden"
                                }
                            >
                                El formato del N° de RUC es incorrecto.
                            </small>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const bloqueTabs = <TabView
                            activeIndex={activeIndex}
                            onTabChange={(e) => {
                                setActiveIndex(e.index);
                                if (e.index === 2) setBanderaTabPersonasContacto(!banderaTabPersonasContacto);
                            }}
                            className="mt-5"
                        >
        <TabPanel header="General">
            <div className="flex mb-0.5 flex-wrap ">
                <div className="w-36 shrink-0">Telf. Celular 1 : </div>
                <div className="shrink-0">
                    <InputText
                        id="in"
                        value={estadoDCF.domain.screen.general.telcel1}
                        className="p-0 w-32 rounded-none"
                        onChange={(e) => {
                            dispatchDCF(
                                new ActionClienteSet("general.telcel1", e.target.value)
                                /* new Action.SetGeneralTelcel1(
                                    e.target.value
                                ) */
                            );
                        }}
                        disabled={estadoDCF.flag.procesandoComando}
                    />
                    <small
                        id=""
                        className={
                            estadoDCF.error.telcel1Blanc ? "mb-1.5 block p-error" : "hidden"
                        }
                    >
                        Se requiere el Número del Primer Celular.
                    </small>
                    <small
                        id=""
                        className={
                            estadoDCF.error.telcel1MF ? "mb-1.5 block p-error" : "hidden"
                        }
                    >
                        El formato del Número de Teléfono es incorrecto.
                    </small>
                </div>
            </div>
            <div className="flex mb-0.5 flex-wrap">
                <div className="w-36 shrink-0">Telf. Celular 2 : </div>
                <div className="shrink-0">
                    <InputText
                        id="in"
                        value={estadoDCF.domain.screen.general.telcel2}
                        className="p-0 w-32 rounded-none"
                        onChange={(e) =>
                            dispatchDCF(
                                new ActionClienteSet("general.telcel2", e.target.value)
                                /* new Action.SetGeneralTelcel2(
                                    e.target.value
                                ) */
                            )
                        }
                        disabled={estadoDCF.flag.procesandoComando}
                    />
                    <small
                        id=""
                        className={
                            estadoDCF.error.telcel2MF ? "mb-1.5 block p-error" : "hidden"
                        }
                    >
                        El formato del Número de Teléfono es incorrecto.
                    </small>
                </div>
            </div>
            <div className="flex mb-0.5 flex-wrap">
                <div className="w-36 shrink-0">Telf. Fijo : </div>
                <div className="shrink-0">
                    <InputText
                        id="in"
                        value={estadoDCF.domain.screen.general.telfijo}
                        className="p-0 w-32 rounded-none"
                        onChange={(e) =>
                            dispatchDCF(
                                new ActionClienteSet("general.telfijo", e.target.value)
                                /* new Action.SetGeneralTelfijo(
                                    e.target.value
                                ) */
                            )
                        }
                        disabled={estadoDCF.flag.procesandoComando}
                    />
                    <small
                        id=""
                        className={
                            estadoDCF.error.telfijoMF ? "mb-1.5 block p-error" : "hidden"
                        }
                    >
                        El formato del Número de Teléfono es incorrecto.
                    </small>
                </div>
            </div>
            <div className="flex mb-0.5  flex-wrap">
                <div className="w-36 shrink-0">Email : </div>
                <div className="shrink-0">
                    <InputText
                        id="in"
                        value={estadoDCF.domain.screen.general.email}
                        className="p-0 w-full rounded-none"
                        onChange={(e) =>
                            dispatchDCF(
                                new ActionClienteSet("general.email", e.target.value)
                                /* new Action.SetGeneralEmail(
                                    e.target.value
                                ) */
                            )
                        }
                        // disabled={true}
                    />
                    <small
                        id=""
                        className={
                            estadoDCF.error.emailMF ? "mb-1.5 block p-error" : "hidden"
                        }
                    >
                        El formato del e-mail es incorrecto.
                    </small>
                </div>
            </div>
            <div className="flex mb-0.5 flex-wrap">
                <div className="w-36 shrink-0">Sitio Web o Blog : </div>
                <div className="shrink-0">
                    <InputText
                        id="in"
                        value={estadoDCF.domain.screen.general.sitioblog}
                        className="p-0 w-full rounded-none"
                        onChange={(e) =>
                            dispatchDCF(
                                new ActionClienteSet("general.sitioblog", e.target.value)
                                /* new Action.SetGeneralSitioBlog(
                                    e.target.value
                                ) */
                            )
                        }
                        // disabled={true}
                    />
                    <small
                        id=""
                        className={
                            estadoDCF.error.sitioWebMF ? "mb-1.5 block p-error" : "hidden"
                        }
                    >
                        El formato del Sitio Web es incorrecto.
                    </small>
                </div>
            </div>
        </TabPanel>

        <TabPanel header="Cobranza">
            <div className="flex mb-0.5">
                <div className="w-48 shrink-0">Modo de Cobro : </div>
                <div className="pl-0 shrink-0">
                    <Dropdown
                        value={estadoDCF.domain.screen.cobranza.modoCobro}
                        options={ModelosDeCombos.modosCobro}
                        onChange={(e) =>
                            dispatchDCF(new AccionesFact.SetCobranzaModoCobro(e.value))
                        }
                        placeholder="Seleccionar..."
                        className="p-0 w-full rounded-none"
                        panelClassName="p-0"
                        disabled={true}
                    />
                </div>
            </div>
            <div className="flex mb-0.5">
                <div className="w-48 shrink-0">Tipo de Comprobante : </div>
                <div className="pl-0 shrink-0">
                    <Dropdown
                        value={estadoDCF.domain.screen.cobranza.tipoComprobante}
                        options={ModelosDeCombos.tiposComprobantes}
                        onChange={(e) =>
                            dispatchDCF(
                                new AccionesFact.SetCobranzaTipoComprobante(e.value)
                            )
                        }
                        placeholder="Seleccionar..."
                        className="p-0 w-full rounded-none"
                        panelClassName="p-0"
                        // disabled={
                        //     !estadoDCF.cobranza.controlTipoComprobante
                        // }
                        disabled={true}
                    />
                </div>
            </div>
            <div className="flex">
                <div className="w-48 shrink-0">Ciclo de Facturación : </div>
                <div className="pl-0">
                    <Dropdown
                        value={estadoDCF.domain.screen.cobranza.cicloFact}
                        options={ModelosDeCombos.ciclosFact}
                        onChange={(e) =>
                            dispatchDCF(new AccionesFact.SetCobranzaCicloFact(e.value))
                        }
                        placeholder="Seleccionar..."
                        className="p-0 w-full rounded-none"
                        panelClassName="p-0"
                        // disabled={!estadoDCF.cobranza.controlCicloFact}
                        disabled={true}
                    />
                </div>
            </div>
        </TabPanel>

        <TabPanel
            header="Personas de Contacto"
            contentClassName="flex justify-between flex-wrap"
        >
            <div className="pr-6 w-72">
                <ContextMenu
                    ref={menuListaNombresPersContact}
                    model={[
                        {
                            label: "Eliminar Contacto",
                            command: () => {
                                dispatchDCF(
                                    new AccionesFact.EliminarContactoAct(
                                        estadoDCF.personasContacto.valorListaNombres
                                    )
                                );
                                //console.log("eliminar contacto");
                            },
                        },
                    ]}
                    //popup
                    // baseZIndex={10}
                    // style={{ marginTop: "4px", marginLeft: "-3px" }}
                    className={"w-fit"}
                    disabled={true}
                />
                <DataTable
                    value={estadoDCF.personasContacto.listaNombres}
                    contextMenuSelection={estadoDCF.personasContacto.valorListaNombres}
                    onContextMenuSelectionChange={(e) => {
                        // console.log("cambiar seleccion", e.value);
                        //console.log("context menu selecction", e);
                        dispatchDCF(
                            new AccionesFact.SetPersonaContactoValorListaNombresOperaciones(
                                e.value
                            )
                        );
                    }}
                    onContextMenu={(e) => {
                        // console.log("mostrar menu");
                        menuListaNombresPersContact.current.show(e.originalEvent);
                        //cm.current.show(e)
                    }}
                    responsiveLayout="scroll"
                    selectionMode="single"
                    selection={estadoDCF.personasContacto.valorListaNombres}
                    onRowClick={(e) => {
                        //console.log("cambiar seleccion", e.value);
                        // console.log("change selecction", e);
                        dispatchDCF(
                            new AccionesFact.SetPersonaContactoValorListaNombres(e.data)
                        );
                    }}
                    tableClassName="p-0 w-full rounded-none listaSinHead border"
                    tableStyle={{ height: "10.4rem" }}
                    header={null}
                    scrollable
                    scrollHeight="10.4rem"
                    isDataSelectable={() => false}
                    // loading={true}
                >
                    <Column
                        field="nombre"
                        header={null}
                        headerStyle={{ padding: 0, height: 0 }}
                        // style={{ padding: "0rem" }}
                        // className="p-0"
                        // bodyStyle={{ padding: "0rem" }}
                        bodyClassName="p-0 mb-0.5"
                    ></Column>
                </DataTable>
            </div>
            <div>
                <div className="flex flex-wrap mb-0.5">
                    <div className="w-32">Nombre : </div>
                    <div className="pl-0">
                        <InputText
                            ref={nomPersContactInput}
                            value={estadoDCF.personasContacto.atributos.nombre}
                            className="p-0 w-64 rounded-none"
                            onChange={(e) => {
                                dispatchDCF(
                                    new AccionesFact.SetPersonaContactoAtributosNombre(
                                        e.target.value
                                    )
                                );
                                // console.log("change");
                            }}
                            onBlur={(e) => {
                                // console.log("blur");
                                dispatchDCF(
                                    new AccionesFact.SetPersonasContactoActNombre()
                                );
                            }}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-0.5">
                    <div className="w-32">Rol : </div>
                    <div className="pl-0">
                        <InputText
                            id="in"
                            value={estadoDCF.personasContacto.atributos.rol}
                            className="p-0 w-full rounded-none"
                            onChange={(e) =>
                                dispatchDCF(
                                    new AccionesFact.SetPersonaContactoAtributosRol(
                                        e.target.value
                                    )
                                )
                            }
                            onBlur={(e) => {
                                // console.log("blur");
                                dispatchDCF(new AccionesFact.SetPersonasContactoActRol());
                            }}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-0.5">
                    <div className="w-32">Telf. Celular 1 : </div>
                    <div className="pl-0">
                        <InputText
                            id="in"
                            value={estadoDCF.personasContacto.atributos.telcel1}
                            className="p-0 w-full rounded-none"
                            onChange={(e) =>
                                dispatchDCF(
                                    new AccionesFact.SetPersonaContactoAtributosTelcel1(
                                        e.target.value
                                    )
                                )
                            }
                            onBlur={(e) => {
                                // console.log("blur");
                                dispatchDCF(
                                    new AccionesFact.SetPersonasContactoActTelcel1()
                                );
                            }}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-0.5">
                    <div className="w-32">Telf. Celular 2 : </div>
                    <div className="pl-0">
                        <InputText
                            id="in"
                            value={estadoDCF.personasContacto.atributos.telcel2}
                            className="p-0 w-full rounded-none"
                            onChange={(e) =>
                                dispatchDCF(
                                    new AccionesFact.SetPersonaContactoAtributosTelcel2(
                                        e.target.value
                                    )
                                )
                            }
                            onBlur={(e) => {
                                // console.log("blur");
                                dispatchDCF(
                                    new AccionesFact.SetPersonasContactoActTelcel2()
                                );
                            }}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-0.5">
                    <div className="w-32">Telf. Fijo : </div>
                    <div className="pl-0">
                        <InputText
                            id="in"
                            value={estadoDCF.personasContacto.atributos.telfijo}
                            className="p-0 w-full rounded-none"
                            onChange={(e) =>
                                dispatchDCF(
                                    new AccionesFact.SetPersonaContactoAtributosTelfijo(
                                        e.target.value
                                    )
                                )
                            }
                            onBlur={(e) => {
                                // console.log("blur");
                                dispatchDCF(
                                    new AccionesFact.SetPersonasContactoActTelfijo()
                                );
                            }}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-0.5">
                    <div className="w-32">E-mail : </div>
                    <div className="pl-0">
                        <InputText
                            id="in"
                            value={estadoDCF.personasContacto.atributos.email}
                            className="p-0 w-full rounded-none"
                            onChange={(e) =>
                                dispatchDCF(
                                    new AccionesFact.SetPersonaContactoAtributosEmail(
                                        e.target.value
                                    )
                                )
                            }
                            onBlur={(e) => {
                                // console.log("blur");
                                dispatchDCF(new AccionesFact.SetPersonasContactoActEmail());
                            }}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
        </TabPanel>
    </TabView>

    let bloqueAtributos=null;
    
    if(estadoDCF.flag.cargandoEntidad){
        bloqueAtributos = (
            <>
                <div className="flex flex-row justify-between">                    
                        <Skeleton width="45%" height="6rem"></Skeleton>               
                        <Skeleton width="40%" height="6rem"></Skeleton>                
                </div>
                <br></br>
                <br></br>
                <div>
                    <Skeleton width="100%" height="10rem"></Skeleton> 
                </div>
            </>
        );
        
    }else{
        bloqueAtributos = (<>
            <div className="flex justify-between flex-wrap">{bloqueTipoCliente}</div>
            {bloqueTabs}
        </>)
    }

    return (
        <Dialogo
            ventana={ventana}
            cabecera={header}
            modal={estadoDCF.flag.modal}
            posicionZ={ventana.posicionZ}
            pie={footer}
            estilo={{ width: "48rem", minHeight: "70vh", top: "15vh", margin: "0" }}
        >
            <div className="flex justify-between">
                <div>
                    <div className="flex">
                        <div className="w-40">Id. de Cliente : </div>
                        <div>{estadoDCF.domain.screen.idCliente}</div>
                    </div>
                    <div className="flex">
                        <div className="w-40"></div>
                        {/* <div className="w-40">Código de Cliente : </div> */}
                        <div>
                            {/* <InputText
                                id="in"
                                value={estadoDCF.domain.screen.codigoCliente}
                                className="p-0 w-24 rounded-none"
                                onChange={(e) => {
                                    dispatchDCF(
                                        new Action.SetCodigoCliente(
                                            e.target.value
                                        )
                                        // new AccionesFact.SetCodigoCliente(e.target.value)
                                    );
                                }}
                                maxLength="7"
                                disabled={estadoDCF.flag.procesandoComando}
                            />
                            <small
                                id=""
                                className={
                                    estadoDCF.error.codClientMF
                                        ? "mb-1.5 block p-error"
                                        : "hidden"
                                }
                            >
                                El formato del Cod. de Cliente es incorrecto.
                            </small> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex">
                        <div className="w-40 shrink-0">Fecha de Registro : </div>
                        <div>{estadoDCF.domain.screen.fechaReg}</div>
                    </div>
                    <div className="flex">
                        <div className="w-40">Estado : </div>
                        <div>{EstadosClienteAFacturar[estadoDCF.domain.screen.estado]}</div>
                    </div>
                </div>
            </div>

            <hr className="my-3"></hr>

            {bloqueAtributos}
            {/* <div className="flex justify-between flex-wrap">{bloqueTipoCliente}</div> */}

            {/* {bloqueTabs} */}
        </Dialogo>
    );
};

export default DialogoClienteAFacturar;
