

export default function MenuItemsOrdenes(dispatch) {
    return [
        {
            label: "Nueva Orden de Venta",
            icon: "pi pi-fw pi-plus",
            // command: abrirVentanaCrearFia,
        },
        {
            label: "Listar Ordenes de Venta",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
    ];
}

