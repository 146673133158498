import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";

import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./output.css";
import "./App.css";

import PresentadorPrincipal from "./_principal/componentes/presentadores/PresentadorPrincipal";
import PresentadorLogueo from "./_seguridad/componentes/presentadores/PresentadorLogueo";

import GlobalContext from "./_comun/contexto/GlobalContext";
// import * as Evento from "./comun/Eventos";
/** @returns {React.element}  PresentadorAppEnrutado*/
/** @returns {React.element}  PresentadorApp*/

function App() {
    return (
        <BrowserRouter>
            <React.StrictMode>
                <PresentadorDeLasRutasDelApp />
            </React.StrictMode>
        </BrowserRouter>
    );
}

/** @returns {React.element}  PresentadorApp*/
function PresentadorDeLasRutasDelApp() {
    const [usuario, setUsuario] = useState(null);
    //useNavigate solo puede usarse en un contexto dentro de un BrowserRouter
    const navigate = useNavigate();

    if (usuario === null) {
        const cargarPantallaPrincipal = (usuario) => {
            setUsuario(usuario);
            navigate("/", { replace: true });
        };

        const presentadorLogueo = (
            <PresentadorLogueo
                cargarPantallaPrincipal={cargarPantallaPrincipal}
            />
        );
        return RutasAlPresentadorLogueo(presentadorLogueo);
    
    } else {
        const cargarPantallaLogin = () => {
            setUsuario(null);
            navigate("/login", { replace: true });
        };

        const contextoGlobal = (
            <GlobalContext.Provider value={{ cargarPantallaLogin, usuario }}>
                <PresentadorPrincipal />
            </GlobalContext.Provider>
        );
        return RutasAlPresentadorPrincipal(contextoGlobal);
    }
}

function RutasAlPresentadorLogueo(presentadorLogueoConfigurado) {
    return (
        <Routes>
            <Route path="/login" element={presentadorLogueoConfigurado} />
            <Route path="*" element={<Navigate to="login" />} />
        </Routes>
    );
}

function RutasAlPresentadorPrincipal(presentadorPrincipalConfigurado) {
    return (
        <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={presentadorPrincipalConfigurado} />
        </Routes>
    );
}

export default App;
