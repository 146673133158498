/** ya no se usa estas constantes */
const DialogType = {
    CLIENTE_A_FACTURAR:   0,
    ITEM: 1,
    PAGO: 2,
    PUNTO_DE_SERVICIO: 3,
    QUERY_CLIENTES_A_FACTURAR:  4,
    QUERY_ITEMS:  5,
    QUERY_PUNTOS_DE_SERVICIO:  6,
    QUERY_PAGOS:  7,
};

const ModosOp = {
    CREAR: 0,     //La operacion va usar la data en los campos ("data actual") para crear una entidad
    MODIFICAR: 1, //La operacion va usar La data en los campos ("data actual") para modificar una entidad
    OK: 2 //La operacion va dejar de mostrar la data en los campos ("data actual") de una entidad
};


export { DialogType, ModosOp };
