import React, { useState, useContext, useRef, useEffect } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";

import GlobalContext from "../../contexto/GlobalContext";
// import OrigenTypes from "../../reductores/OrigenTypes";
// import ActionTypesWidgets from "../../reductores/ActionTypesWidgets";
import EliminarVentana from "../../../_principal/reductores/acciones/widgets/EliminarVentana";
import PosicionarVentanaTop from "../../../_principal/reductores/acciones/widgets/PosicionarVentanaTop";

export default function Dialogo({ ventana, cabecera, pie, estilo, modal, posicionZ, children }) {
    const { dispatchPrincipal } = useContext(GlobalContext);
    const [nroRenderizados, forceUpdateRenderizados] = useState(0);

    // console.log("ventana dialogo ",ventana);
    if (nroRenderizados === 0) {
        // console.log(ventana.posicionZ);
        // ventana.posicionZ--;
        // ventana.posicionZ=5;
        // ventana.posicionZ=ventana.posicionZ-1;
        // posicionZ--;
    }

    // console.log("Render");

    const onShow = () => {
        // console.log("onShow");
        // forceUpdateRenderizados((x) => x + 1);
        // setPosicionZ(posicionZ+1);
        // dialogoPrime.current.maskStyle={zIndex: ventana.posicionZ}
        // console.log('dialogoPrime: ',dialogoPrime.current.maskStyle);
    };

    const onClick = (eventoSynDom) => {
        dispatchPrincipal(new PosicionarVentanaTop(ventana));
        eventoSynDom.stopPropagation();
        // console.log(eventoSynDom);
    };
    
    const clickCerrar = (eventoSynDom) => {
        dispatchPrincipal(new EliminarVentana(ventana));
        eventoSynDom.stopPropagation();
    };

    const clickMinimizar = (eventoSynDom) => {
        // dispatchPrincipal(new EliminarVentana(ventana.id));
        dispatchPrincipal(new EliminarVentana(ventana));
        eventoSynDom.stopPropagation();

    };

    const cabeceraDialogo = (
        <div className="flex justify-between">
            {cabecera.elementoReact}
            <div className="flex items-center">
                <Button
                    icon="pi pi-window-minimize"
                    className="p-button-rounded p-button-help p-button-outlined mr-1.5 w-9 h-9"
                    aria-label="Cancel"
                    onClick={clickMinimizar}
                    disabled={cabecera.enProgreso}
                />
                <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-help p-button-outlined  w-9 h-9"
                    aria-label="Cancel"
                    onClick={clickCerrar}
                    disabled={cabecera.enProgreso}
                />
            </div>
        </div>
    );

    return (
        <Dialog
            // ref={funcionRef}
            // ref={dialogoRef}
            modal={modal}
            header={cabeceraDialogo}
            visible={true}
            style={estilo}
            // modal={false}
            footer={pie}
            // onHide={(eventoDom) => onClick("CERRAR_DIALOGO", eventoDom)}
            onShow={onShow}
            onClick={onClick}
            // onClick={(eventoDom) => onClick("CLICK_PANEL", eventoDom)}
            keepInViewport={false}
            maskStyle={{zIndex: `${posicionZ}`}}
            closable={false}
            position={"top"}
            // className="top-1/8"
        >
            {children}
        </Dialog>
    );
}
