
import { v4 } from "uuid";
// import {ModosOp, TipoDeCliente, TipoDeDoc} from './Constantes'
// import {CargarErrorComunicEnClienteAFacturar} from "./AccionesTypeFact";
import * as AccionesFact from "./acciones/AccionesTypeFact";
// import * as Action from './Actions'
// import {estadoCargado} from '..reductores/EstadosDialogoClienteAFacturar'
// import Estado from "./EstadoDialogoClienteAFacturar";
// import AccionSet from './ActionSet'
import ActionTypeFact from './acciones/ActionTypeFact'
// import React from "react";
// import { Error } from "../../comun/Eventos";

/** 
 * @param {object} state Estado
 * @param {object} action Accion
 * @returns {void} Retorna un nuevo estado 
 */
export default function FactReducer(state, action) {

    const estadoTemp = {...state,domain:{...state.domain},};
    estadoTemp.domain.cargado={};
    const nuevoEstado = JSON.parse(JSON.stringify(estadoTemp));
    nuevoEstado.domain.cargado = state.domain.cargado;  

    switch (true) {
        // case action instanceof AccionesFact.SetCodigoCliente:
        case action instanceof ActionTypeFact:
            return action.ejecutar(state, nuevoEstado);

        case action instanceof AccionesFact.SetCobranzaModoCobro:
            // let habilitado = true;
            console.log(action);
            const habilitado = action.payload === "exo" ? false : true;
            // console.log(habilitado);
            return {
                ...state,
                cobranza: {
                    ...state.cobranza,
                    modoCobro: action.payload,
                    controlTipoComprobante: habilitado,
                    controlCicloFact: habilitado,
                },
            };

        case action instanceof AccionesFact.SetCobranzaTipoComprobante:
            return {
                ...state,
                cobranza: {
                    ...state.cobranza,
                    tipoComprobante: action.payload,
                },
            };

        case action instanceof AccionesFact.SetCobranzaCicloFact:
            return {
                ...state,
                cobranza: { ...state.cobranza, cicloFact: action.payload },
            };

        case action instanceof AccionesFact.SetPersonaContactoValorListaNombres:
            //Se selecciona el elemento de la lista de Nombres que ya esta seleccionado
            // console.log("inicio: ", action);
            // console.log("inicio: ", action.payload.nombre);

            if (action.payload.nombre === "Definir Nuevo") {
                console.log("Definir nuevo yaya");
                return {
                    ...state,
                    personasContacto: {
                        ...state.personasContacto,
                        valorListaNombres: action.payload,
                        atributos: {
                            nombre: "Definir Nuevo",
                            rol: "",
                            telcel1: "",
                            telcel2: "",
                            telfijo: "",
                            email: "",
                        },
                        flagControNombreSelected:
                        !state.personasContacto.flagControNombreSelected,
                    },
                    
                };
            }

            // console.log("buscar contacto: ");

            //Seleccionar el contacto seleccionado
            const contactoSelec = state.personasContacto.contactosAct.find(
                (contacto) => {
                    return contacto.nombre === action.payload.nombre ? true : false;
                }
            );

            // console.log("contacto selec: ", contactoSelec);

            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    valorListaNombres: action.payload,
                    atributos: {
                        nombre: contactoSelec.nombre,
                        rol: contactoSelec.rol,
                        telcel1: contactoSelec.telcel1,
                        telcel2: contactoSelec.telcel2,
                        telfijo: contactoSelec.telfijo,
                        email: contactoSelec.email,
                    },
                    flagControNombreSelected:
                    !state.personasContacto.flagControNombreSelected,
                },
               
            };
        
        case action instanceof AccionesFact.SetPersonaContactoValorListaNombresOperaciones:{
            //Se selecciona el elemento de la lista de Nombres que ya esta seleccionado
            // console.log("inicio: ", action);
            // console.log("inicio: ", action.payload.nombre);
                      
            if (action.payload.nombre === "Definir Nuevo") {
                // console.log("Definir nuevo yaya");
                return {
                    ...state,
                    personasContacto: {
                        ...state.personasContacto,
                        valorListaNombres: action.payload,
                        atributos: {
                            nombre: "Definir Nuevo",
                            rol: "",
                            telcel1: "",
                            telcel2: "",
                            telfijo: "",
                            email: "",
                        },
                    },
                    
                };
            }

            // console.log("buscar contacto: ");

            //Seleccionar el contacto seleccionado
            const contactoSelec = state.personasContacto.contactosAct.find(
                (contacto) => {
                    return contacto.nombre === action.payload.nombre ? true : false;
                }
            );

            // console.log("contacto selec: ", contactoSelec);

            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    valorListaNombres: action.payload,
                    atributos: {
                        nombre: contactoSelec.nombre,
                        rol: contactoSelec.rol,
                        telcel1: contactoSelec.telcel1,
                        telcel2: contactoSelec.telcel2,
                        telfijo: contactoSelec.telfijo,
                        email: contactoSelec.email,
                    },
                },
               
            };
        }

        case action instanceof AccionesFact.SetPersonaContactoListaNombres:
            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    cicloFact: action.payload,
                },
            };

        case action instanceof AccionesFact.SetPersonaContactoAtributosNombre:
            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    atributos: {
                        ...state.personasContacto.atributos,
                        nombre: action.payload,
                    },
                },
            };

        case action instanceof AccionesFact.SetPersonaContactoAtributosRol:
            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    atributos: {
                        ...state.personasContacto.atributos,
                        rol: action.payload,
                    },
                },
            };

        case action instanceof AccionesFact.SetPersonaContactoAtributosTelcel1:
            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    atributos: {
                        ...state.personasContacto.atributos,
                        telcel1: action.payload,
                    },
                },
            };
        case action instanceof AccionesFact.SetPersonaContactoAtributosTelcel2:
            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    atributos: {
                        ...state.personasContacto.atributos,
                        telcel2: action.payload,
                    },
                },
            };
        case action instanceof AccionesFact.SetPersonaContactoAtributosTelfijo:
            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    atributos: {
                        ...state.personasContacto.atributos,
                        telfijo: action.payload,
                    },
                },
            };

        case action instanceof AccionesFact.SetPersonaContactoAtributosEmail:
            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    atributos: {
                        ...state.personasContacto.atributos,
                        email: action.payload,
                    },
                },
            };

        case action instanceof AccionesFact.SetPersonasContactoActNombre: {
            // console.log(
            //     "Console 1: " + state.personasContacto.atributos.nombre
            // );
            console.log("vamos");
            const campoNombre = {nombre: state.personasContacto.atributos.nombre};
            const valorSelec = state.personasContacto.valorListaNombres;

            if (campoNombre.nombre === "") {
                console.log("nombreCampo Vacio");
                return {
                    ...state,
                    personasContacto: {
                        ...state.personasContacto,
                        atributos: {
                            ...state.personasContacto.atributos,
                            nombre: valorSelec.nombre,
                        },
                    },
                };
            }

            const nombreCampoExisteEnLista = state.personasContacto.listaNombres.some(
                (nombreDeLista) => {
                    return nombreDeLista.nombre === campoNombre.nombre ? true : false;
                }
            );

            // const nombreCampoExisteEnLista =
            //     state.personasContacto.listaNombres.includes(campoNombre);
            
            
            if (nombreCampoExisteEnLista) {
                return {
                    ...state,
                    personasContacto: {
                        ...state.personasContacto,
                        atributos: {
                            ...state.personasContacto.atributos,
                            nombre: valorSelec.nombre,
                        },
                    },
                };
            }

            //Caso nombre de Campo no existe en la Lista de Nombres de Contacto
            
            if (valorSelec.nombre === "Definir Nuevo") {
                //crear nuevo Contacto
                return {
                    ...state,
                    personasContacto: {
                        ...state.personasContacto,
                        contactosAct: [
                            ...state.personasContacto.contactosAct,
                            {
                                nombre: state.personasContacto.atributos.nombre,
                                rol: state.personasContacto.atributos.rol,
                                telcel1:
                                    state.personasContacto.atributos.telcel1,
                                telcel2:
                                    state.personasContacto.atributos.telcel2,
                                telfijo:
                                    state.personasContacto.atributos.telfijo,
                                email: state.personasContacto.atributos.email,
                            },
                        ],
                        valorListaNombres: campoNombre,
                        listaNombres: [
                            ...state.personasContacto.listaNombres.slice(0, -1),
                            campoNombre,
                            {nombre:"Definir Nuevo"},
                        ],
                    },
                };
            }

            //Caso: valorSelec !== "Definir Nuevo". valorSelect es un nombre de contacto diferente de "Definir Nuevo"
            //Actualizar nombre en Lista de Nombres
            const nombreContAnt = valorSelec.nombre;
            const nombreContNew = campoNombre.nombre;
            
            // console.log("2 console: " + state.personasContacto.atributos.nombre");
            const contactoSelec = state.personasContacto.contactosAct.find(
                (contacto) => {
                    return contacto.nombre === nombreContAnt ? true : false;
                }
            );
            contactoSelec.nombre = nombreContNew;

            state.personasContacto.valorListaNombres = {nombre:nombreContNew};

            const indiceContAnt = state.personasContacto.listaNombres.findIndex(
                (nombre) => {
                    return nombre.nombre === nombreContAnt ? true : false;
                }
            );

            state.personasContacto.listaNombres[indiceContAnt].nombre = nombreContNew;

            return {
                ...state,
            };
        }

        case action instanceof AccionesFact.SetPersonasContactoActRol: {
            const valorListaNombres = state.personasContacto.valorListaNombres;
            if (valorListaNombres.nombre === "Definir Nuevo") {
                return state;
            }

            const contactoSelec = state.personasContacto.contactosAct.find(
                (contacto) => {
                    return contacto.nombre === valorListaNombres.nombre ? true : false;
                }
            );

            const rolNew = state.personasContacto.atributos.rol;
            contactoSelec.rol = rolNew;

            return { ...state };
        }

        case action instanceof AccionesFact.SetPersonasContactoActTelcel1: {
            const valorListaNombres = state.personasContacto.valorListaNombres;
            if (valorListaNombres.nombre === "Definir Nuevo") {
                return state;
            }

            const contactoSelec = state.personasContacto.contactosAct.find(
                (contacto) => {
                    return contacto.nombre === valorListaNombres.nombre ? true : false;
                }
            );

            const telcel1New = state.personasContacto.atributos.telcel1;
            contactoSelec.telcel1 = telcel1New;

            return { ...state };
        }

        case action instanceof AccionesFact.SetPersonasContactoActTelcel2: {
            const valorListaNombres = state.personasContacto.valorListaNombres;
            if (valorListaNombres.nombre === "Definir Nuevo") {
                return state;
            }

            const contactoSelec = state.personasContacto.contactosAct.find(
                (contacto) => {
                    return contacto.nombre === valorListaNombres.nombre ? true : false;
                }
            );

            const telcel2New = state.personasContacto.atributos.telcel2;
            contactoSelec.telcel2 = telcel2New;

            return { ...state };
        }

        case action instanceof AccionesFact.SetPersonasContactoActTelfijo: {
            const valorListaNombres = state.personasContacto.valorListaNombres;
            if (valorListaNombres.nombre === "Definir Nuevo") {
                return state;
            }

            const contactoSelec = state.personasContacto.contactosAct.find(
                (contacto) => {
                    return contacto.nombre === valorListaNombres.nombre ? true : false;
                }
            );

            const telfijoNew = state.personasContacto.atributos.telfijo;
            contactoSelec.telfijo = telfijoNew;

            return { ...state };
        }

        case action instanceof AccionesFact.SetPersonasContactoActEmail: {
            const valorListaNombres = state.personasContacto.valorListaNombres;
            if (valorListaNombres.nombre === "Definir Nuevo") {
                return state;
            }

            const contactoSelec = state.personasContacto.contactosAct.find(
                (contacto) => {
                    return contacto.nombre === valorListaNombres.nombre ? true : false;
                }
            );

            const emailNew = state.personasContacto.atributos.email;
            contactoSelec.email = emailNew;

            return { ...state };
        }

        case action instanceof AccionesFact.EliminarContactoAct: {
            
            const indiceContacto = state.personasContacto.listaNombres.findIndex(
                (nombreDeLista) => {
                    return nombreDeLista.nombre === action.payload.nombre ? true : false;
                }
            );
            state.personasContacto.listaNombres.splice(indiceContacto,1);
            state.personasContacto.contactosAct.splice(indiceContacto,1)

            const valorSelec = state.personasContacto.listaNombres[indiceContacto];
            
            if (valorSelec.nombre === "Definir Nuevo") {
                //console.log("Definir nuevo yaya");
                return {
                    ...state,
                    personasContacto: {
                        ...state.personasContacto,
                        valorListaNombres: valorSelec,
                        atributos: {
                            nombre: "Definir Nuevo",
                            rol: "",
                            telcel1: "",
                            telcel2: "",
                            telfijo: "",
                            email: "",
                        },
                    },
                    
                };
            }

            // console.log("buscar contacto: ");

            //Seleccionar el contacto seleccionado
            const contactoSelec = state.personasContacto.contactosAct.find(
                (contacto) => {
                    return contacto.nombre === valorSelec.nombre ? true : false;
                }
            );

            // console.log("contacto selec: ", contactoSelec);

            return {
                ...state,
                personasContacto: {
                    ...state.personasContacto,
                    valorListaNombres: valorSelec,
                    atributos: {
                        nombre: contactoSelec.nombre,
                        rol: contactoSelec.rol,
                        telcel1: contactoSelec.telcel1,
                        telcel2: contactoSelec.telcel2,
                        telfijo: contactoSelec.telfijo,
                        email: contactoSelec.email,
                    },
                },
               
            };
            
        }
        
        case "TOGGLE_TASK_DONE":
        // console.log("task toggle done");

        // return {
        //     tasks: state.tasks.map((task) => {
        //         if (task.id === payload) {
        //             console.log(task);
        //             // task.done = !task.done;
        //             // task.id=`${Math.floor(3+Math.random() * 2)}`;
        //             // task.done= Math.floor(Math.random() * 2) ? false: true;
        //             // const toggle = Math.floor(Math.random() * 2) ? true : false;
        //             let toggle = true;
        //             if (task.done) {
        //                 toggle = false;
        //             } else {
        //                 toggle = true;
        //             }
        //             console.log("toogle: " + toggle);
        //             // task.done = toggle;
        //             // task.title= "basofia";
        //             // task.id=555555555
        //             // const newTask = { id: task.id,
        //             // done: !task.done, title: task.title,
        //             // description: task.description };
        //             const newTask = { ...task, done: !task.done };
        //             // console.log(newTask);
        //             console.log(task);
        //             // return task;
        //             return newTask;
        //             // return {...task, done: !task.done};
        //         } else {
        //             return task;
        //         }
        //     }),
        // };
        break;

        default:
            break;
    }
}
