import { v4 } from "uuid";

export default class Ventana {
    constructor() {
        this.id = v4();
        this.posicionZ = 0;
        // this.modal = false;
    }

    /**@abstract */
    puedeHacersePasarPor(ventana) {}
}
