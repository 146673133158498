import { ErrorDeDominio } from "../../_comun/Errores";

export class ErrorDeSesionNoValida extends ErrorDeDominio {
    constructor(data) {
        super();
        this.data = data;
    }
}

export * from "../../_comun/Errores";


