import axios from "axios";
import * as Error from "../../_comun/Errores";
import { keyDeErrorApi, keyDeErrorLogin } from "../../_comun/Constantes";
import { StatusCodes } from "http-status-codes";

const ServicioRegistroDeClienteAFacturar = async (dataCliente) => {
    try {
        const respAxiosExito = await hacerRequerimientoAxios(dataCliente);
        return getDataDeRespuestaAxiosExitosa(respAxiosExito);
    } catch (error) {
        if (error.hasOwnProperty("isAxiosError") && error.isAxiosError) {
            lanzarErrorDeRespuestaAxiosErronea(error);
        }
        if (error instanceof Error.ErrorDeProtocoloDelServidor) {
            throw error;
        }
    }
};

const hacerRequerimientoAxios = async (dataCliente) => {
    const baseUrl =
        process.env.REACT_APP_API_SERVER + "/facturacion/clientesAFacturar";
    const respAxios = await axios({
        method: "post",
        url: baseUrl,
        data: dataCliente,
        headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
    });
    return respAxios;
};

const getDataDeRespuestaAxiosExitosa = (respAxiosExito) => {
    const respuestaApi = respAxiosExito.data;
    let dataDeCreacionDeCliente;
    try {
        //parsear respuestaApi de tal manera que la data tenga el formato de exito correcto
        //Si hay error en parseo:
        // throw new ErrorDeApp.ErrorFormatoDeCom();
        dataDeCreacionDeCliente = respuestaApi.data;
    } catch (error) {
        throw new Error.ErrorDeProtocoloDelServidor();
    }
    return dataDeCreacionDeCliente;
};

const lanzarErrorDeRespuestaAxiosErronea = (errorAxios) => {
    const respAxiosError = errorAxios.request;

    if (respAxiosError.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        //Se parsea respuestaApiComoString
        const respuestaApiComoString = respAxiosError.response;

        let respuestaApi = null;
        try {
            respuestaApi = JSON.parse(respuestaApiComoString);
            const errores = respuestaApi.data.erroresEnCliente;
            if (errores.length < 1 || errores.length > 20) {
                throw new Error("Error Parsing Login");
            }
            /* let usuNoValido=false, otros = 0;
            respuestaApi.data.erroresEnCliente.forEach((e) => {
                if (e.key === keyDeErrorLogin.usuarioNoVal) {
                    usuNoValido=true;
                }
                if (e.key === keyDeErrorLogin.nombreVacio) {
                    otros++;
                }
                if (e.key === keyDeErrorLogin.contraVacia) {
                    otros++;
                }
            });
            if(usuNoValido && otros>0) throw new Error("Error Parsing Login");
            if(!usuNoValido && otros===0) throw new Error("Error Parsing Login"); */
        } catch (error) {
            throw new Error.ErrorDeProtocoloDelServidor();
        }

        throw new Error.ListaDeErroresDelDominio({
            errores: respuestaApi.data.erroresEnCliente,
        });
    }

    if (respAxiosError.status === StatusCodes.BAD_REQUEST) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: “errBodyMF”, y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDelCliente();

        throw new Error.ErrorDeProtocoloDelCliente();
    }

    if (respAxiosError.status === StatusCodes.NOT_FOUND) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: "errPathMF", y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();

        throw new Error.ErrorDeProtocoloDelCliente();
    }

    if (respAxiosError.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: "errFlujoTrab", y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();

        throw new Error.ErrorDelFlujoDeTrabajoDelBackend();
    }

    // Para los restastes HTTP Codes se debe lanzar un ErrorDesconocidoDelServidor
    if (respAxiosError.status) {
        throw new Error.ErrorDeProtocoloDelServidor();
    }
    // Cuando no se puede conectar al servidor entonces respAxiosError.status === 0, debido a que no se recibio ninguna respuesta
    throw new Error.ErrorNoConex();

    /*const esCodigoHttpDelAPI =
        respAxiosError.status === StatusCodes.UNAUTHORIZED ||
        respAxiosError.status === StatusCodes.BAD_REQUEST ||
        respAxiosError.status === StatusCodes.UNPROCESSABLE_ENTITY ||
        respAxiosError.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (esCodigoHttpDelAPI) {
        const respuestaApiComoString = respAxiosError.response;

        let respuestaApi = null;
        try {
            //parsear respuestaApiComoString de tal manera que sea un objeto JSON aceptable
            respuestaApi = JSON.parse(respuestaApiComoString);
            
            //parsear respuestaApi de tal manera que existan las keys aceptables (errSis, codClientMF, y otros)
            //Si hay error:  throw new Error.ErrorFormatoDeCom();
        } catch (error) {
            throw new Error.ErrorDeProtocoloDelServidor();
        }
        
        const keyErrorRespuesta = respuestaApi.key;
        if (keyErrorRespuesta === keyDeErrorApi.ErroresDominio) {
            const keysDeErroresEnCliente =
                respuestaApi.data.erroresEnCliente.map((e) => {
                    return e.key;
                });
            throw new Error.ErroresDelDominio({
                errores: keysDeErroresEnCliente,
            });
        }
        if (keyErrorRespuesta === keyDeErrorApi.ErrorMalFormato) {
            throw new Error.ErrorDeProtocoloDelCliente();
        }
        if (keyErrorRespuesta === keyDeErrorApi.ErrorDelFlujoDeTrabajo) {
            throw new Error.ErrorDelFlujoDeTrabajoDelBackend();
        }
        if (keyErrorRespuesta === keyDeErrorApi.ErrorDesconocido) {
            throw new Error.ErrorDelFlujoDeTrabajoDelBackend();
        }
    }

    // Para los restastes HTTP Codes se debe lanzar un ErrorDesconocidoDelServidor
    if (respAxiosError.status) {
        throw new Error.ErrorDeProtocoloDelServidor();
    }

    // Cuando no se puede conectar al servidor entonces respAxiosErrorstatus === 0, debido a que no se recibio ninguna respuesta
    throw new Error.ErrorNoConex();*/
};

export default ServicioRegistroDeClienteAFacturar;
