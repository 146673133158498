
import ActionTypePrincipal from "./ActionTypePrincipal";

export default function PrincipalReducer(state, action) {

    const nuevasVentanas = state.ventanas.map((ventana)=>{
        return ventana.clonar();
    });
    const nuevoEstado = {...state, ventanas: nuevasVentanas};

    switch (true) {
        
        case action instanceof ActionTypePrincipal:
            return action.ejecutar(state, nuevoEstado);
        
        /* case action instanceof ActionNotificarEvento:
            return action.ejecutar(state, nuevoEstado); */

        default:
            return state;
    }
}


