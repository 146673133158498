

export default function MenuItemsAdministracion(dispatch) {
    return [
        {
            label: "Nuevo Usuario",
            icon: "pi pi-fw pi-user-plus",
        },

        {
            label: "Listar Usuarios",
            icon: "pi pi-fw pi-users",
        },
    ];
}

