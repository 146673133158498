
import Ventana from "../../../_principal/reductores/ventanas/Ventana";

export default class VentanaQueryClientesAFacturar extends Ventana {
    constructor() {
        super();
        this.ventanaCargando = null
    }

    puedeHacersePasarPor(ventanaAAbrir) {
        if (!(ventanaAAbrir instanceof VentanaQueryClientesAFacturar)) {
            return false;
        }

        return true;
    }

    clonar(){
        const nuevaVentana = new VentanaQueryClientesAFacturar();
        nuevaVentana.id              = this.id;
        nuevaVentana.posicionZ       = this.posicionZ         
        nuevaVentana.ventanaCargando = this.ventanaCargando
        return nuevaVentana;
    }
}
