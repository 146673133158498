
import AbrirVentana from "../../reductores/acciones/widgets/AbrirVentana";
import VentanaClienteAFacturar from '../../../facturacion/reductores/ventanas/VentanaClienteAFacturar'
import VentanaQueryClientesAFacturar from '../../../facturacion//reductores/ventanas/VentanaQueryClientesAFacturar'


export default function MenuItemsFacturacion(dispatch) {
    return [
        {
            label: "Nueva Orden de Cobro de Instalación",
            icon: "pi pi-fw pi-plus",
            // command: () => dispatch(new AbrirVentanaClienteAFacturar()),
        },
        {
            label: "Listar Ordenes de Cobro de Instalación",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
        {
            separator: true,
        },
        {
            label: "Listar Servicios Comerciales de Internet",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
        {
            separator: true,
        },
        {
            label: "Reporte de Servicios",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
        {
            separator: true,
        },
        {
            label: "Nuevo Cliente a Facturar",
            icon: "pi pi-fw pi-list",
            command: () => {
                const nuevaVentana = new VentanaClienteAFacturar();
                dispatch(new AbrirVentana(nuevaVentana));
                // dispatch(new AbrirVentanaNuevoClienteAFacturar())
            },
        },
        {
            label: "Listar Clientes a Facturar",
            icon: "pi pi-fw pi-list",
            command: () => {
                const nuevaVentana = new VentanaQueryClientesAFacturar;
                dispatch(new AbrirVentana(nuevaVentana));
                // dispatch(new AbrirVentanaQueryClientesAFacturar())
            },
        },
        {
            separator: true,
        },
        {
            label: "Nuevo Item a Facturar",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
        {
            label: "Listar Items a Facturar",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
        {
            separator: true,
        },
        {
            label: "Lista de Eventos",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
        {
            separator: true,
        },
        {
            label: "Configuración",
            icon: "pi pi-fw pi-list",
            // command: abrirVentanaQueryFias,
        },
    ];
}
