
import MostrarVentana from './MostrarVentana';


export default class CargarYMostrarVentana extends MostrarVentana {
    
    ejecutar(state, nuevoEstado){
        const ventanaAAbrir = this.payload;
        ventanaAAbrir.posicionZ = state.ventanas.length + 1;
        nuevoEstado.ventanas.push(ventanaAAbrir);
        return nuevoEstado;
    
    }
}