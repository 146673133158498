import { useState, useRef, useEffect, useContext } from "react";
import { Button } from "primereact/button";

import GlobalContext from "../../../_comun/contexto/GlobalContext";

import ServicioDeCerradoDeSesion from "../../servicios/ServicioDeCerradoDeSesion";
import * as TipoDeErrorSeguridad from "../../servicios/Errores";

import { NotificacionToastDeError } from "../../../_comun/Notificaciones";
import CodigoDeError from "../../../_comun/CodigosDeErrorDeRespuestas.json";
import DataDeRespuestas_Comun from "../../../_comun/DataEstaticaDeRespuestas.json";
import ActionCancelarCerrarSesion from "../../../_principal/reductores/acciones/seguridad/ActionCancelarCerrarSesion";
import ActionCerrarSesion from "../../../_principal/reductores/acciones/seguridad/ActionCerrarSesion";

const PresentadorCerrarSesion = () => {
    const { cargarPantallaLogin, usuario, dispatchPrincipal, notificar } =
        useContext(GlobalContext);

    const [sensorDeCerrarSesion, setSensorDeCerrarSesion] = useState(false);
    const [banderaCerrandoSesion, setBanderaCerrandoSesion] = useState(false);

    const isMounted = useRef(false);
    // let sensorDeCerrarSesion = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            cerrarSesion();
            // console.log("hola");
            // registrarClienteAFacturar();
        } else {
            isMounted.current = true;
        }
    }, [sensorDeCerrarSesion]);

    const cerrarSesion = async () => {
        try {
            await ServicioDeCerradoDeSesion();
            cargarPantallaLogin();
        } catch (error) {
            if (error instanceof TipoDeErrorSeguridad.ErrorDeSesionNoValida) {
                cargarPantallaLogin();
                return;
            }
            if (error instanceof TipoDeErrorSeguridad.ErrorDeLaCapaDeServicios) {
                setBanderaCerrandoSesion(false);
                dispatchPrincipal(new ActionCancelarCerrarSesion());

                let nombreDeError = error.constructor.name;
                notificar(
                    new NotificacionToastDeError(
                        DataDeRespuestas_Comun[nombreDeError].titulo,
                        DataDeRespuestas_Comun.labelCodigoError,
                        CodigoDeError[nombreDeError],
                        DataDeRespuestas_Comun[nombreDeError].descripcion
                    )
                );
                return;
            }
        }
    };

    return (
        <>
            {/* <PantallaLogin
            nombreUsuario={nombreUsuario}
            contraseña={contraseña}
            loading={loading}
            banderasError={banderasError}
            setearNombreUsuario={setearNombreUsuario}
            setearContraseña={setearContraseña}
            manejarLogueo={manejarLogueo}
            ref={refPantallaLoguin}
        /> */}

            <h1 className="mb-1">{usuario.alias}</h1>
            <Button
                label="Salir"
                icon="pi pi-power-off"
                className="p-1 m-0 w-20"
                onClick={() => {
                    setBanderaCerrandoSesion(true);
                    setSensorDeCerrarSesion(!sensorDeCerrarSesion);
                    dispatchPrincipal(new ActionCerrarSesion());
                }}
                // onClick={()=>notificarEventoDeSeguridad(new Evento.CerroSesion())}
                loading={banderaCerrandoSesion}
            />
        </>
    );
};

export default PresentadorCerrarSesion;
