import axios from "axios";
// import * as ErrorServicio from './ErroresServicios'
import * as TipoDeError from "../../_comun/Errores";
import { keyDeErrorApi, keyDeErrorLogin } from "../../_comun/Constantes";
import { StatusCodes } from "http-status-codes";
import { ErrorDeSesionNoValida } from "./Errores";

const ServicioDeCerradoDeSesion = async () => {
    try {
        const respAxiosExito = await hacerRequerimientoAxios();
        getDataDeRespuestaAxiosExitosa(respAxiosExito);
    } catch (error) {
        if (error.hasOwnProperty("isAxiosError") && error.isAxiosError) {
            lanzarErrorDeRespuestaAxiosErronea(error);
        }
        if (error instanceof TipoDeError.ErrorDeProtocoloDelServidor) {
            throw error;
        }
    }
};

const hacerRequerimientoAxios = async () => {
    const baseUrl = process.env.REACT_APP_API_SERVER + "/cerrar/misesion";
    const respAxios = await axios({
        method: "delete",
        url: baseUrl,
        headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
    });
    return respAxios;
};

const getDataDeRespuestaAxiosExitosa = (respAxiosExito) => {
    const respuestaApi = respAxiosExito.data;

    try {
        //parsear respuestaApi de tal manera que la data tenga el formato de exito correcto
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();
        if(respuestaApi.exito !== true)throw new TipoDeError.ErrorDeProtocoloDelServidor();
    } catch (error) {
        throw new TipoDeError.ErrorDeProtocoloDelServidor();
    }

    // return dataDeCreacionDeCliente;
};

const lanzarErrorDeRespuestaAxiosErronea = (errorAxios) => {
    
    const respAxiosError = errorAxios.request;

    if (respAxiosError.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        //Se parsea respuestaApiComoString 
        const respuestaApiComoString = respAxiosError.response;
        
        let respuestaApi=null;
        try {
            respuestaApi = JSON.parse(respuestaApiComoString);
            
            const cond1 = respuestaApi.exito === false;
            const cond2 = respuestaApi.data.key === "errSesionIdVacia";
            const cond3 = respuestaApi.data.key === "errSesionIdNoVal";

            if(cond1 && cond2){throw new TipoDeError.ErrorDeProtocoloDelCliente();}
            if(cond1 && cond3){throw new ErrorDeSesionNoValida();}
            
        } catch (error) {
            throw new TipoDeError.ErrorDeProtocoloDelServidor();
        }

        throw new TipoDeError.ErrorDeProtocoloDelServidor();

    }

    if (respAxiosError.status === StatusCodes.BAD_REQUEST) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: “errBodyMF”, y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDelCliente();

        throw new TipoDeError.ErrorDeProtocoloDelCliente();
    }

    if (respAxiosError.status === StatusCodes.NOT_FOUND) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: "errPathMF", y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();

        throw new TipoDeError.ErrorDeProtocoloDelCliente();
    }

    if (respAxiosError.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        const respuestaApiComoString = respAxiosError.response;

        //parsear respuestaApiComoString de tal manera que existan los atributos aceptables “key”: "errFlujoTrab", y otros
        //Si hay error en parseo:
        // throw new Error.ErrorDeProtocoloDeServidor();

        throw new TipoDeError.ErrorDelFlujoDeTrabajoDelBackend();
    }

    // Para los restastes HTTP Codes se debe lanzar un ErrorDesconocidoDelServidor
    if (respAxiosError.status) {
        throw new TipoDeError.ErrorDeProtocoloDelServidor();
    }
    // Cuando no se puede conectar al servidor entonces respAxiosError.status === 0, debido a que no se recibio ninguna respuesta
    throw new TipoDeError.ErrorNoConex();
};

export default ServicioDeCerradoDeSesion;
