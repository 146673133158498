
import MostrarVentana from "./MostrarVentana";


export default class PosicionarVentanaTop extends MostrarVentana {
  
    ejecutar(state, nuevoEstado) {
        if (state.ventanas.length < 1) return state;

        const ventanaASubir = this.payload;
        const ventanaTop = state.ventanas[state.ventanas.length - 1];
        const esVentanaASubirTop = ventanaASubir === ventanaTop;

        if (esVentanaASubirTop) {
            return state;
        }

        const indiceVentanaASubir = state.ventanas.findIndex((ventana, indice) => {
            return ventanaASubir === ventana ? true : false;
        });

        if (indiceVentanaASubir < 0) {
            return state;
        }

        const intercambiarPosicionesZ = (indice1, indice2) => {
            const posicionZIndice1 = state.ventanas[indice1].posicionZ;
            nuevoEstado.ventanas[indice1].posicionZ = state.ventanas[indice2].posicionZ;
            nuevoEstado.ventanas[indice2].posicionZ = posicionZIndice1;
        };

        const intercambiarVentanas = (indice1, indice2) => {
            const ventana1 = nuevoEstado.ventanas[indice1];
            const ventana2 = nuevoEstado.ventanas[indice2];

            nuevoEstado.ventanas.splice(indice1, 1, ventana2);
            nuevoEstado.ventanas.splice(indice2, 1, ventana1);
        };

        const indiceVentanaTop = state.ventanas.length - 1;

        intercambiarPosicionesZ(indiceVentanaASubir, indiceVentanaTop);
        intercambiarVentanas(indiceVentanaASubir, indiceVentanaTop);

        // console.log("ventanas ", state.ventanas);
        return nuevoEstado;
        // return { ventanas: [...state.ventanas] };
    }

      
    ejecutar3(state, nuevoEstado) {
        const idVentanaASubir = this.payload;
        const idVentanaTop = state.ventanas[state.ventanas.length - 1].id;
        const esVentanaASubirTop = idVentanaASubir === idVentanaTop;
        
        if (esVentanaASubirTop) {
            return state;
        } else {
            state.ventanas.some((ventana, indice) => {
                if (idVentanaASubir === ventana.id) {
                    // console.log("iterador");
                    // indiceVentanaAPosicionar = indice;
                    const intercambiarPosicionesZ = (indice1, indice2) => {
                        const posicionZIndice1 = state.ventanas[indice1].posicionZ;
                        state.ventanas[indice1].posicionZ = state.ventanas[indice2].posicionZ;
                        state.ventanas[indice2].posicionZ = posicionZIndice1;
                    };
    
                    const intercambiarVentanas = (indice1, indice2) => {
                        const ventana1 = state.ventanas[indice1];
                        const ventana2 = state.ventanas[indice2];
    
                        state.ventanas.splice(indice1, 1, ventana2);
                        state.ventanas.splice(indice2, 1, ventana1);
                    };
    
                    // console.log("reducer ", payload);
                    const indiceVentanaAPosicionar = indice;
                    const indiceVentanaTop = state.ventanas.length - 1;
    
                    intercambiarPosicionesZ(indiceVentanaAPosicionar, indiceVentanaTop);
                    intercambiarVentanas(indiceVentanaAPosicionar, indiceVentanaTop);
    
                    // console.log("ventanas ", state.ventanas);
                    return { ventanas: [...state.ventanas] };
                }
            });
            return state;
        }
    }
}
