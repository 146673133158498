import React, { useContext} from "react";

// import { Menubar } from "primereact/menubar";
// import { Image } from "primereact/image";
// import { Menu } from "primereact/menu";

import BotonMenu from "./BotonMenu";
import MenuItemsOrdenes from './MenuItemsOrdenes'
import MenuItemsFacturacion from './MenuItemsFacturacion'

import GlobalContext from "../../../_comun/contexto/GlobalContext";
import MenuItemsLogistica from "./MenuItemsLogistica";
import MenuItemsRRHH from "./MenuItemsRRHH";
import MenuItemsSeguridad from "./MenuItemsSeguridad";
import MenuItemsAdministracion from "./MenuItemsAdministracion";

import * as Evento from "../../../_comun/Eventos";
import PresentadorCerrarSesion from "../../../_seguridad/componentes/presentadores/PresentadorCerrarSesion";

const BarraMenu = () => {
    const {
        // usuario,
        // cerrarSesion,
        // abrirVentanaClienteAFacturar,
        // notificarEventoDeSeguridad,
        dispatchPrincipal,
        // banderaCerrandoSesion,
        // abrirVentanaCrearFia,
        // abrirVentanaQueryFias,
        // cargarPantallaLogin
    } = useContext(GlobalContext);


    return (
        <div className="flex p-menubar mt-4 mx-4 px-0 py-0.5 justify-between">

            <div className="flex">
            
                <BotonMenu label="Ordenes" icono="pi pi-copy" items={MenuItemsOrdenes(dispatchPrincipal)}></BotonMenu>
                <BotonMenu label="Facturación" icono="pi pi-dollar" items={MenuItemsFacturacion(dispatchPrincipal)}></BotonMenu>
                <BotonMenu label="Logística" icono="pi pi-server" items={MenuItemsLogistica(dispatchPrincipal)}></BotonMenu>  
                <BotonMenu label="Recursos Humanos" icono="pi pi-users" items={MenuItemsRRHH(dispatchPrincipal)}></BotonMenu>  
                <BotonMenu label="Seguridad" icono="pi pi-lock" items={MenuItemsSeguridad(dispatchPrincipal)}></BotonMenu>  
                <BotonMenu label="Administración" icono="pi pi-chart-line" items={MenuItemsAdministracion(dispatchPrincipal)}></BotonMenu>  
                             
            </div>

            <div id="usuarioLogueado" className="flex items-center rounded ml-1 mr-3">
                <i
                    className="pi pi-fw pi-user mr-2"
                    style={{ fontSize: "3em" }}
                ></i>
                <div className="flex flex-col items-center">
                    <PresentadorCerrarSesion />
                </div>
            </div>  
        </div>
        
        
    );
};
export default BarraMenu;
