import ActionTypeFact from "../ActionTypeFact";
import {ModosOp} from '../../../Constantes'
// import Estado from '../EstadoDialogoClienteAFacturar'

export default class CargaDeErroresDelDominioAlCrearCliente extends ActionTypeFact {

    ejecutar(estado, nuevoEstado) {

        for (const property in nuevoEstado.error) {
            nuevoEstado.error[property]=false;
        }

        this.payload.errores.forEach( (error) => {
            nuevoEstado.error[error.key]=true;
        });

        nuevoEstado.flag.procesandoComando = false;
        return nuevoEstado;
    }

}