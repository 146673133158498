import React, { useState, useEffect, useContext, useRef } from "react";
import GlobalContext from "../../../_comun/contexto/GlobalContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";

import EliminarVentana from "../../../_principal/reductores/acciones/widgets/EliminarVentana";
import Dialogo from "../../../_comun/componentes/pantallas/Dialogo";
import BuscarClientesAFacturar from "../../reductores/acciones/dispatch/BuscarClientesAFacturar";
import VentanaClienteAFacturar from "../../reductores/ventanas/VentanaClienteAFacturar";
import { ModosOp } from "../../../_comun/Constantes";
import MostrarVentana from "../../../_principal/reductores/acciones/widgets/MostrarVentana";
import PosicionarVentanaTop from "../../../_principal/reductores/acciones/widgets/PosicionarVentanaTop";
import CargarYMostrarVentana from "../../../_principal/reductores/acciones/widgets/CargarYMostrarVentana";

const DialogQueryClientesAFacturar = ({
    ventana,
    dispatchQ,
    estadoQ,
    buscarClientesAFacturar,
    buscarUnSoloClienteAFacturar,
}) => {
    const { dispatchPrincipal, estadoPrin } = useContext(GlobalContext);

    // const isMounted = useRef(false);

    /*  useEffect(() => {
        if (isMounted.current) {
            buscarClientesAFacturar();
        } else {
            isMounted.current = true;
        }
    }, [estadoQ.flag.sensorDeAccionABuscar]); */

    /* const cancelarClick = (eventoDom) => {
        dispatchPrincipal(new EliminarVentana(ventana));
        eventoDom.stopPropagation();
    }; */
    
    const dobleClickEnFila = (eventoSyn) => {
        const nuevaVentana = new VentanaClienteAFacturar();
        nuevaVentana.estadoCom.flag.modoOperacion = ModosOp.OK;
        nuevaVentana.estadoCom.domain.screen.idCliente = eventoSyn.data.idCliente;
        const ventanaAAbrir = MostrarVentana.getVentanaExistenteEnLaLista(estadoPrin, nuevaVentana);
        if(ventanaAAbrir){
            // ventanaAAbrir.estadoCom.flag.modal = true;
            ventana.ventanaCargando = ventanaAAbrir;
            // dispatchQ(new BuscarUnSoloClienteAFacturar());
            dispatchPrincipal(new PosicionarVentanaTop(ventanaAAbrir));
            buscarUnSoloClienteAFacturar(ventanaAAbrir.idCliente);
        }
        nuevaVentana.estadoCom.flag.modal = true;
        nuevaVentana.estadoCom.flag.cargandoEntidad = true;
        ventana.ventanaCargando = nuevaVentana;
        dispatchPrincipal(new CargarYMostrarVentana(nuevaVentana));
        buscarUnSoloClienteAFacturar(nuevaVentana.estadoCom.domain.screen.idCliente);
        // console.log("paso");
    };

    const footer = (
        <div>
            <Button
                label={"  Buscar  "}
                icon="pi pi-search"
                onClick={(eventoSynDom) => {
                    dispatchQ(new BuscarClientesAFacturar());
                    buscarClientesAFacturar();
                    eventoSynDom.stopPropagation();
                }}
                loading={estadoQ.flag.procesandoComando}
                iconPos="left"
                className="whitespace-pre"
            />
            <Button
                label="Cancelar"
                icon="pi pi-times"
                onClick={(eventoSynDom) => {
                    dispatchPrincipal(new EliminarVentana(ventana));
                    eventoSynDom.stopPropagation();
                }}
                disabled={estadoQ.flag.procesandoComando}
                // className="w-32"
            />
        </div>
    );

    const header = {
        elementoReact: (
            <p className="p-2 bg-gradient-to-r from-purple-100">Lista de Clientes a Facturar</p>
        ),
        enProgreso: estadoQ.flag.procesandoComando,
    };

    const dialogo = (
        <Dialogo
            // visible={true}
            // // style={{ width: "50vw"}}
            // modal={false}
            // onHide={() => onClick("CERRAR_DIALOGO")}
            // onShow={onShow}
            // onClick={() => onClick("CLICK_PANEL")}
            // keepInViewport={false}
            // maskStyle={{ zIndex: posicionZ }}
            ventana={ventana}
            cabecera={header}
            pie={footer}
            estilo={{ width: "80rem", minHeight: "70vh", top: "15vh", margin :"0" }}
            posicionZ={ventana.posicionZ}
            modal={false}
        >
            <DataTable
                value={estadoQ.data}
                paginator
                rows={8}
                showGridlines
                stripedRows
                size="small"
                selectionMode="single"
                // autoLayout={true}
                // autoLayout
                // autoLayout={false}
                // responsiveLayout="stack"
                // responsiveLayout="scroll"
                scrollable
                scrollDirection="both"
                emptyMessage="No se encontró Clientes a Facturar."
                totalRecords={100}
                // columnResizeMode="expand"
                columnResizeMode="fit"
                // resizableColumns
                tableStyle={{ minHeight: "40vh" }}
                onRowDoubleClick={dobleClickEnFila}
            >
                <Column
                    field="idCliente"
                    header="Id. Cliente"
                    style={{ width: "8%" }}
                    // bodyStyle={{ "width": "1%" }}
                    // unstyled
                ></Column>
                <Column
                    field="tipoCliente"
                    header="Tipo / Cliente"
                    style={{ width: "8%" }}
                ></Column>
                <Column field="nombres" header="Nombres" style={{ width: "15%" }}></Column>
                <Column field="apellidos" header="Apellidos" style={{ width: "15%" }}></Column>
                <Column field="tipoDoc" header="Tipo Doc." style={{ width: "10%" }}></Column>
                <Column field="nroDoc" header="Nro. Doc" style={{ width: "9%" }}></Column>
                {/* <Column field="dni" header="DNI" style={{ minWidth: "1rem" }}></Column>
                <Column field="ptp" header="PTP" style={{ minWidth: "1rem" }}></Column>
                <Column
                    field="carnetExt"
                    header="C. Extranjería"
                    style={{ minWidth: "1rem" }}
                ></Column>
                <Column field="pasaporte" header="Pasaporte" style={{ minWidth: "1rem" }}></Column> */}
                <Column field="ruc" header="Nro. RUC" style={{ width: "10%" }}></Column>
                <Column
                    field="razonSocial"
                    header="Razón Social"
                    style={{ width: "25%" }}
                    unstyled
                ></Column>
            </DataTable>
        </Dialogo>
    );

    return dialogo;
};

export default DialogQueryClientesAFacturar;
