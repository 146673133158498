import ActionTypePrincipal from "../../ActionTypePrincipal";


export default class EliminarVentana extends ActionTypePrincipal {

    ejecutar(state, nuevoEstado){
        const ventanaAEliminar = this.payload;
        nuevoEstado.ventanas = state.ventanas.filter(
            (ventana) => ventana !== ventanaAEliminar
        );
        return nuevoEstado;
        // const idVentanaAEliminar = this.payload;
        // nuevoEstado.ventanas = state.ventanas.filter(
        //     (ventana) => ventana.id !== idVentanaAEliminar
        // );
        // return nuevoEstado;
    }
}