
import PosicionarVentanaTop from './PosicionarVentanaTop';
import MostrarVentana from './MostrarVentana';
import CargarYMostrarVentana from './CargarYMostrarVentana';


export default class AbrirVentana extends MostrarVentana {
    
    ejecutar(state, nuevoEstado){
        const ventanaAAbrir = this.payload;

        const ventanaReemplazante = MostrarVentana.getVentanaExistenteEnLaLista(state, ventanaAAbrir);
        if (ventanaReemplazante) {
            const action = new PosicionarVentanaTop(ventanaReemplazante);
            return action.ejecutar(state, nuevoEstado);
        } else {
            const action = new CargarYMostrarVentana(ventanaAAbrir);
            return action.ejecutar(state, nuevoEstado);
        }
    }
    
    ejecutar2(state, nuevoEstado){
        const ventanaAAbrir = this.payload;

        /* const ventanaReemplazante = state.ventanas.find((ventana) => {
            if (ventana.puedeHacersePasarPor(ventanaAAbrir)){
                return true;
            } else {
                return false;
            }
        }); */
        const ventanaReemplazante = MostrarVentana.getVentanaExistenteEnLaLista(ventanaAAbrir);
        if (ventanaReemplazante) {
            const action = new PosicionarVentanaTop(ventanaReemplazante);
            return action.ejecutar(state, nuevoEstado);
        } else {
            /* const nuevaVentana = {
                tipo: DialogType.CLIENTE_A_FACTURAR,
                modoOp: ModosOp.OK,
                id: v4(),
                evento: null,
                posicionZ: state.ventanas.length + 1,
            }; */
            ventanaAAbrir.posicionZ = state.ventanas.length + 1;
            nuevoEstado.ventanas.push(ventanaAAbrir);
            return nuevoEstado;
            // return { ventanas: [...state.ventanas, nuevaVentana] };
        }

    }
}