import ActionTypeFact from "../ActionTypeFact";
import {ModosOp} from '../../../../_principal/Constantes'
// import Estado from '../../EstadoDialogoClienteAFacturar'

export default class CargaDeClienteRegistrado extends ActionTypeFact {

    ejecutar(estado, nuevoEstado) {
               
        nuevoEstado.domain.screen.idCliente = this.payload.idCliente;
        nuevoEstado.domain.screen.fechaReg = this.payload.fechaReg;
        nuevoEstado.domain.screen.estado = this.payload.estado;
        nuevoEstado.flag.procesandoComando = false;
        nuevoEstado.flag.modoOperacion = ModosOp.OK;

        for (const property in estado.error) {
            nuevoEstado.error[property]=false;
        }

        nuevoEstado.domain.cargado = JSON.parse(JSON.stringify(estado.domain.screen));
        delete nuevoEstado.domain.cargado.idCliente;
        delete nuevoEstado.domain.cargado.fechaReg;
        delete nuevoEstado.domain.cargado.estado;
        nuevoEstado.domain.update={};
        nuevoEstado.domain.nroUpdates=0;

        return nuevoEstado;
    }

}